import s from "./CourseEx.module.css"
import {CourseDesc, CourseDescription, PageTitle, Razdel, SubTitle} from "../Modules/Modules";
import cab from "../../assets/Imgs/Teacher.png"
import bc from "../../assets/Imgs/BigCourse.png"
import 'tippy.js/dist/tippy.css';
import {CalendarExample} from "../MainPages/Cabinet";


const CourseExample1 = () => {

    return (
        <div className={s.main}>
            <div className={s.coursePage}>
                <div className={s.courseLeft}>
                    <SubTitle title="Опека и попечительство"/>
                    <PageTitle
                        title="Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента"/>
                    <TeacherBlock/>
                    {window.innerWidth < 976 ?
                        <div className={s.courseRight}>
                            <p></p>
                            <BuyWindow/>
                        </div> : <></>
                    }
                    <div className={s.courseImg}>
                        <img src={bc} alt=""/>
                    </div>
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                    <CourseDescription title="Раздел 1.">
                        <Razdel/>
                    </CourseDescription>
                    <CourseDescription title="Раздел 2.">
                    </CourseDescription>
                    <CourseDescription title="Календарь">
                        <CalendarExample/>
                    </CourseDescription>
                </div>
                {window.innerWidth >= 976 ?
                    <div className={s.courseRight}>
                        <BuyWindow/>
                    </div> : <></>
                }
            </div>
        </div>
    )
}
export default CourseExample1



export const TeacherBlock = ({name, position}) => {
    return (
        <div className={s.courseTeacher}>
            <div className={s.prepod}>
                Руководитель программы
            </div>
            <div className={s.info}>
                <div className={s.name}>
                    <img src={cab} alt=""/>
                    <div>
                        {name}
                    </div>
                </div>
                <div className={s.position}>
                    {position}
                </div>
            </div>
        </div>
    )
}

const BuyWindow = () => {
    return (
        <div className={s.buy}>
            <div className={s.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                {/*<div style={{fontSize: "16px", paddingBottom: "10px"}}>*/}
                {/*    <span><strong>0 человек</strong></span> прошли курс*/}
                {/*</div>*/}
                <div style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}>
                    36 академических часов
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px"
                }}>
                    <div style={{fontSize: "12px", color: "#808080"}}>
                        Стоимость курса
                    </div>
                    <div style={{fontSize: "18px"}}>
                        {/*<strong>3 100 ₽</strong>*/}
                    </div>
                </div>
                <div className={s.buyButton}>
                    <a href="/registration">Записаться на курс</a>
                </div>
            </div>
        </div>
    )
}

