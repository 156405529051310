import s from "./Buttons.module.css"
import {BookOutlined} from "@ant-design/icons";

export const DefaultPinkButton = ({title, url}) => {
    return (
        <span className={s.defaultPinkButton}>
            <a href={url}>{title}</a>
        </span>
    )
}

export const GrayButton = ({title = null, url, svg = null}) => {
    return (
        <span className={s.grayButton}>
            <a href={url}>{title === null ? svg : title}</a>
        </span>
    )
}

export const DocRef = ({title, url}) => {
    return (
        <div className={s.doc}>
            <BookOutlined style={{fontSize: "24px", color: "#cb2b62", paddingRight: "4px"}}/><a href={url}>{title}</a>
        </div>
    )
}