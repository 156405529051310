import s from "./Main.module.css"
import cc from "./Cabinet.module.css"
import {CourseDescription, PageTitle} from "../Modules/Modules";
import "../Modules/Avatar/Avatar.module.css"
import * as Yup from 'yup';
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useState} from "react";
import {useNavigate} from "react-router-dom";


const Zayavka1 = () => {
    let navigate = useNavigate()
    const [allow, setAllow] = useState(true)
    const [data, setData] = useState('')
    const [send, setSend] = useState('Отправить')


    const InfoSchema = Yup.object().shape({
        course: Yup.string()
            .required('Обязательное поле!'),
        name: Yup.string()
            .required('Обязательное поле!'),
        pay: Yup.string()
            .required('Обязательное поле!'),
        email: Yup.string()
            .required('Обязательное поле!')
            .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/, "Неправильный формат почты"),
        phone: Yup.string()
            .min(11, "Введите полный номер")
            .required('Обязательное поле!'),
        education: Yup.string()
            .required('Обязательное поле!'),
        work: Yup.string()
            .required('Обязательное поле!'),
        place: Yup.string()
            .required('Обязательное поле!'),
        region: Yup.string()
            .required('Обязательное поле!'),
    });

    return (
        <div className={s.main}>

            <div className={s.cabinetTitle}>
                <div className={s.left}>
                    <PageTitle title="Заявка на обучение"/>
                </div>
            </div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    course: '',
                    name: '',
                    pay: '',
                    email: '',
                    phone: '',
                    education: '',
                    work: '',
                    place: '',
                    region: '',
                }}
                onSubmit={(values, {setSubmitting}) => {

                    let data1 = new FormData();
                    data1.append('курс', values.course)
                    data1.append('оплата', values.pay)
                    data1.append('ФИО', values.name)
                    data1.append('телефон', values.phone)
                    data1.append('почта', values.email)
                    data1.append('образование', values.education)
                    data1.append('место_работы', values.work)
                    data1.append('должность', values.place)
                    data1.append('регион', values.region)

                    let config = {
                        method: 'POST',
                        maxBodyLength: Infinity,

                        url: 'https://dpo.fcprc.ru/api/newzayavka',
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        data: data1
                    };
                    setSend('Отправка...')
                    axios
                        .request(config)
                        .then((response) => {

                            setAllow(false)
                            setSend('Отправить')
                            navigate("/zayavka-confirm")
                        })

                }}
                validationSchema={InfoSchema}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <CourseDescription title="Основные данные">
                            <div className={cc.editInfo}>
                                <div className={cc.formStyle}>
                                    <label className={cc.item}>
                                        <div>
                                            Выбор Платного курса <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   required={true}
                                                   style={isWrong(errors.course, touched.course)}
                                                   name="course">
                                                <option disabled selected value="">Выберите курс</option>
                                                <option
                                                    value="Основы медиации для специалистов по работе с семьей, органов опеки и попечительства">Основы
                                                    медиации для специалистов по работе с семьей, органов опеки и
                                                    попечительства 36 ак.ч. - 15000Руб
                                                </option>
                                                {/*<option value="Практикум начинающего медиатора: учимся на кейсах">Практикум начинающего медиатора: учимся на кейсах</option>*/}
                                                <option
                                                    value="Практикум профессионального медиатора: тренируемся на кейсах">Практикум
                                                    профессионального медиатора: тренируемся на кейсах 20 ак.ч. -
                                                    10000Руб
                                                </option>
                                                <option
                                                    value="Медиативно-восстановительные технологии в образовательной организации">Медиативно-восстановительные
                                                    технологии в образовательной организации 36 ак.ч. - 15000Руб
                                                </option>
                                                {/*<option value="Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента">Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента</option>*/}
                                                <option
                                                    value="Формирование ценностей здоровьесбережения и профилактика зависимого поведения обучающихся образовательных организаций">Формирование
                                                    ценностей здоровьесбережения и профилактика зависимого поведения
                                                    обучающихся образовательных организаций 20 ак.ч. - 10000Руб
                                                </option>
                                                {/*<option value="Организация подросткового центра как современного пространства для развития, социализации и самореализации подростков">Организация подросткового центра как современного пространства для развития, социализации и самореализации подростков</option>*/}
                                                <option
                                                    value="Особенности подготовки и сопровождения опекунов или попечителей несовершеннолетних">Особенности
                                                    подготовки и сопровождения опекунов или попечителей
                                                    несовершеннолетних 36 ак.ч.. - 15000Руб
                                                </option>
                                                <option
                                                    value="Психологические проблемы детско-подросткового возраста: профилактика и коррекция в общеобразовательных организациях">Психологические
                                                    проблемы детско-подросткового возраста: профилактика и коррекция в
                                                    общеобразовательных организациях 20 ак.ч. - 10000Руб
                                                </option>
                                            </Field>
                                        </div>
                                        {errors.course && touched.course ? (
                                            <div className={cc.wrong}>{errors.course}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Оплата будет производиться <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   required={true}
                                                   style={isWrong(errors.pay, touched.pay)}
                                                   name="pay">
                                                <option disabled selected value="">-</option>
                                                <option
                                                    value="Юридическим лицом">Юридическим лицом
                                                </option>
                                                <option
                                                    value="Физическим лицом">Физическим лицом
                                                </option>

                                            </Field>
                                        </div>
                                        {errors.pay && touched.pay ? (
                                            <div className={cc.wrong}>{errors.pay}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            ФИО <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={true}
                                                style={isWrong(errors.name, touched.name)}
                                                name="name"/>
                                        </div>
                                        {errors.name && touched.name ? (
                                            <div className={cc.wrong}>{errors.name}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Телефон <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={true}
                                                type="tel"
                                                style={isWrong(errors.phone, touched.phone)}
                                                name="phone"/>
                                        </div>
                                        {errors.phone && touched.phone ? (
                                            <div className={cc.wrong}>{errors.phone}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Почта <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={true}
                                                type="email"
                                                style={isWrong(errors.email, touched.email)}
                                                name="email"/>
                                        </div>
                                        {errors.email && touched.email ? (
                                            <div className={cc.wrong}>{errors.email}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Образование <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   required={true}
                                                   style={isWrong(errors.sex, touched.sex)}
                                                   name="education">
                                                <option disabled selected value="">-</option>
                                                <option
                                                    value="Высшее образование">Высшее образование
                                                </option>
                                                <option
                                                    value="Среднее профессиональное образование">Среднее
                                                    профессиональное образование
                                                </option>
                                                <option
                                                    value="Другое">Другое
                                                </option>

                                            </Field>
                                        </div>
                                        {errors.education && touched.education ? (
                                            <div className={cc.wrong}>{errors.education}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Место работы <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={true}
                                                type="text"
                                                style={isWrong(errors.work, touched.work)}
                                                name="work"/>
                                        </div>
                                        {errors.work && touched.work ? (
                                            <div className={cc.wrong}>{errors.work}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Должность <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={true}
                                                type="text"
                                                style={isWrong(errors.place, touched.place)}
                                                name="place"/>
                                        </div>
                                        {errors.place && touched.place ? (
                                            <div className={cc.wrong}>{errors.place}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Регион
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   style={isWrong(errors.region, touched.region)}
                                                   name="region"
                                                   required={true}>
                                                <option value="">Выберите регион</option>
                                                <option value="Алтайский край">Алтайский край</option>
                                                <option value="Амурская область">Амурская область</option>
                                                <option value="Архангельская область">Архангельская область</option>
                                                <option value="Астраханская область">Астраханская область</option>
                                                <option value="Белгородская область">Белгородская область</option>
                                                <option value="Брянская область">Брянская область</option>
                                                <option value="Владимирская область">Владимирская область</option>
                                                <option value="Волгоградская область">Волгоградская область</option>
                                                <option value="Вологодская область">Вологодская область</option>
                                                <option value="Воронежская область">Воронежская область</option>
                                                <option value="Донецкая Народная Республика">Донецкая Народная
                                                    Республика
                                                </option>
                                                <option value="Еврейская автономная область">Еврейская автономная
                                                    область
                                                </option>
                                                <option value="Забайкальский край">Забайкальский край</option>
                                                <option value="Запорожская область">Запорожская область</option>
                                                <option value="Ивановская область">Ивановская область</option>
                                                <option value="Иркутская область">Иркутская область</option>
                                                <option value="Кабардино-Балкарская Республика">Кабардино-Балкарская
                                                    Республика
                                                </option>
                                                <option value="Калининградская область">Калининградская область</option>
                                                <option value="Калужская область">Калужская область</option>
                                                <option value="Камчатский край">Камчатский край</option>
                                                <option value="Карачаево-Черкесская Республика">Карачаево-Черкесская
                                                    Республика
                                                </option>
                                                <option value="Кемеровская область">Кемеровская область</option>
                                                <option value="Кировская область">Кировская область</option>
                                                <option value="Костромская область">Костромская область</option>
                                                <option value="Краснодарский край">Краснодарский край</option>
                                                <option value="Красноярский край">Красноярский край</option>
                                                <option value="Крым">Крым</option>
                                                <option value="Курганская область">Курганская область</option>
                                                <option value="Курская область">Курская область</option>
                                                <option value="Ленинградская область">Ленинградская область</option>
                                                <option value="Липецкая область">Липецкая область</option>
                                                <option value="Луганская Народная Республика">Луганская Народная
                                                    Республика
                                                </option>
                                                <option value="Магаданская область">Магаданская область</option>
                                                <option value="Москва">Москва</option>
                                                <option value="Московская область">Московская область</option>
                                                <option value="Мурманская область">Мурманская область</option>
                                                <option value="Ненецкий автономный округ">Ненецкий автономный округ
                                                </option>
                                                <option value="Нижегородская область">Нижегородская область</option>
                                                <option value="Новгородская область">Новгородская область</option>
                                                <option value="Новосибирская область">Новосибирская область</option>
                                                <option value="Омская область">Омская область</option>
                                                <option value="Оренбургская область">Оренбургская область</option>
                                                <option value="Орловская область">Орловская область</option>
                                                <option value="Пензенская область">Пензенская область</option>
                                                <option value="Пермский край">Пермский край</option>
                                                <option value="Приморский край">Приморский край</option>
                                                <option value="Псковская область">Псковская область</option>
                                                <option value="Республика Адыгея">Республика Адыгея</option>
                                                <option value="Республика Алтай">Республика Алтай</option>
                                                <option value="Республика Башкортостан">Республика Башкортостан</option>
                                                <option value="Республика Бурятия">Республика Бурятия</option>
                                                <option value="Республика Дагестан">Республика Дагестан</option>
                                                <option value="Республика Ингушетия">Республика Ингушетия</option>
                                                <option value="Республика Калмыкия">Республика Калмыкия</option>
                                                <option value="Республика Карелия">Республика Карелия</option>
                                                <option value="Республика Коми">Республика Коми</option>
                                                <option value="Республика Марий Эл">Республика Марий Эл</option>
                                                <option value="Республика Мордовия">Республика Мордовия</option>
                                                <option value="Республика Саха (Якутия)">Республика Саха (Якутия)
                                                </option>
                                                <option value="Республика Северная Осетия — Алания">Республика Северная
                                                    Осетия —
                                                    Алания
                                                </option>
                                                <option value="Республика Татарстан">Республика Татарстан</option>
                                                <option value="Республика Тыва">Республика Тыва</option>
                                                <option value="Республика Хакасия">Республика Хакасия</option>
                                                <option value="Ростовская область">Ростовская область</option>
                                                <option value="Рязанская область">Рязанская область</option>
                                                <option value="Самарская область">Самарская область</option>
                                                <option value="Санкт-Петербург">Санкт-Петербург</option>
                                                <option value="Саратовская область">Саратовская область</option>
                                                <option value="Сахалинская область">Сахалинская область</option>
                                                <option value="Свердловская область">Свердловская область</option>
                                                <option value="Севастополь">Севастополь</option>
                                                <option value="Смоленская область">Смоленская область</option>
                                                <option value="Ставропольский край">Ставропольский край</option>
                                                <option value="Тамбовская область">Тамбовская область</option>
                                                <option value="Тверская область">Тверская область</option>
                                                <option value="Томская область">Томская область</option>
                                                <option value="Тульская область">Тульская область</option>
                                                <option value="Тюменская область">Тюменская область</option>
                                                <option value="Удмуртская Республика">Удмуртская Республика</option>
                                                <option value="Ульяновская область">Ульяновская область</option>
                                                <option value="Хабаровский край">Хабаровский край</option>
                                                <option value="Ханты-Мансийский автономный округ —
                                            Югра">Ханты-Мансийский автономный округ —
                                                    Югра
                                                </option>
                                                <option value="Херсонская область">Херсонская область</option>
                                                <option value="Челябинская область">Челябинская область</option>
                                                <option value="Чеченская Республика">Чеченская Республика</option>
                                                <option value="Чувашская Республика">Чувашская Республика</option>
                                                <option value="Чукотский
                                            автономный
                                            округ">Чукотский
                                                    автономный
                                                    округ
                                                </option>
                                                <option value="Ямало-Ненецкий автономный округ">Ямало-Ненецкий
                                                    автономный округ
                                                </option>
                                                <option value="Ярославская область">Ярославская область</option>
                                            </Field>
                                        </div>
                                        {errors.region && touched.region ? (
                                            <div className={cc.wrong}>{errors.region}</div>
                                        ) : null}
                                    </label>
                                </div>
                            </div>
                        </CourseDescription>
                        <div className={cc.final}>
                            <button disabled={!allow} style={!allow ? {cursor: "default"} : {}}
                                    type="submit">{send}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
export default Zayavka1


function isWrong(errors, touched) {
    return errors && touched ? {border: "1px solid red"} : {border: "1px solid #CCCCCC"};
}
