import s from "../MainPages/Main.module.css"
import m from "../Modules/Modules.module.css"
import {Filter, PageTitle, SubTitle} from "../Modules/Modules";
import {DocRef} from "../Modules/Buttons";
import {libratyData} from "../../Examples/libratyData";
import {useEffect, useState} from "react";
import axios from "axios";
import {SearchOutlined} from "@ant-design/icons";

const Library3 = ({title, num1, num2}) => {

    let dop = [
        {
            description: "Обзор практики разрешения судами споров, связанных с воспитанием детей",
            file: "/static/media/op1.c9ba9c34b109bbd4b0da.pdf"
        },
        {
            description: "Обзор практики рассмотрения судами дел о возвращении ребенка",
            file: "/static/media/op2.ff6bc341cb00f15ff994.pdf"
        },
        {
            description: "Обзор судебной практики Верховного Суда Российской Федерации",
            file: "/static/media/op3.94228e3b4d50ae09b5e1.pdf"
        },
        {
            description: "Постановление Пленума Верховного Суда РФ от 05.11.1998 N 15",
            file: "/static/media/op4.9a13f7f969900fed0495.pdf"
        },
        {
            description: "Постановление Пленума Верховного Суда РФ от 14.11.2017 N 44",
            file: "/static/media/op5.dc909feb22621214bf82.pdf"
        },
        {
            description: "Постановление Пленума Верховного Суда РФ от 17.11.2015 N 50",
            file: "/static/media/op6.17cb7850f0c117e01fc6.pdf"
        },
        {
            description: "Постановление Пленума Верховного Суда РФ от 27.05.1998 N 10",
            file: "/static/media/op7.ab6010f25d62ad59e6f6.pdf"
        },
    ]
    let response = [];
    let newResponse = [...dop, ...response]


    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const results = newResponse.filter(p =>
            p.description.toLowerCase().includes(searchTerm)
        );
        setSearchResults(results);
    }, [searchTerm]);


    return (
        <div className={s.main}>
            <PageTitle title={title}/>
            <SubTitle title="Учебно-методическая литература для внеаудиторной работы слушателей"/>
            <div className={m.search}>
                <input id="searchInput" value={searchTerm} onChange={handleChange} type="text" placeholder="Поиск..."/>
                <button id="searchButton"><SearchOutlined/></button>
            </div>
            <div className={s.filterPage}>
                <Filter filterTitle="Все книги" filterArray={libratyData}/>
                <div className={s.filePart}>
                    {
                        searchTerm ? searchResults.map((el) =>
                            <DocRef title={el.description}
                                    url={el.file}/>
                        ) : newResponse.map((el) =>
                            <DocRef title={el.description}
                                    url={el.file}/>
                        )
                    }

                </div>
            </div>
        </div>
    )
}
export default Library3