import s from "../MainPages/Main.module.css"
import {Filter, LibrarySearch, PageTitle, SubTitle} from "../Modules/Modules";
import {libratyData} from "../../Examples/libratyData";

const Library4 = () => {


    return (
        <div className={s.main}>
            <PageTitle title="Обзоры практики Верховного Суда Российской Федерации"/>
            <SubTitle title="Учебно-методическая литература для внеаудиторной работы слушателей"/>
            <LibrarySearch/>
            <div className={s.filterPage}>
                <Filter filterTitle="Все книги" filterArray={libratyData}/>
                <div className={s.filePart}>
                    Записей не найдено!
                </div>
            </div>
        </div>
    )
}
export default Library4