import m from "../../CoursesExamples/CourseEx.module.css"
import {CourseDescription, CourseFilter, PageTitle, SubTitle} from "../../Modules/Modules";
import {TeacherBlock} from "../../CoursesExamples/CourseExample1";
import s from "../../Modules/Modules.module.css";
import file1 from "../../../assets/FilesForDeleteInFuture/MC4/1. ОП_Медиативно_восстановительные_технологии_в_образовательной_организации.pdf"
import file2 from "../../../assets/FilesForDeleteInFuture/MC4/2. Расписание  ПК-С П-3 медиация в образовании.pdf"
import file3 from "../../../assets/FilesForDeleteInFuture/MC4/3. Процедура проведения аттестации.pdf"
import {MC4Array} from "../../CoursesExamples/MediationCourse4";

const MC4Information = () => {
    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Медиация"/>
                    <PageTitle
                        title="Медиативно-восстановительные технологии в образовательной организации"/>
                    <TeacherBlock name="Марина Викторовна Быкова"
                                  position='Аналитик отдела медиации ФГБУ "Центр защиты прав и интересов детей"'/>
                    <p style={{fontSize: "30px"}}></p>
                    <CourseDescription title="Информация для ознакомления">
                        <CourseDesc/>
                    </CourseDescription>
                </div>
                <div className={m.courseRight}>
                    <CourseFilter filterArray={MC4Array}/>
                </div>
            </div>

        </div>
    )
}
export default MC4Information

const CourseDesc = () => {
    return <div className={s.courseDescription}>
        <div className={s.ref}>
            <a href={file1}>ОП Медиативно восстановительные технологии в образовательной организации</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={file2}>Расписание занятий группы ПК-С/П-3 Медиация в образовании</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={file3}>Процедура
                проведения аттестации</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href="https://t.me/+Wb9XsXPSVTc0NTRi">Группа в телеграм</a>
        </div>
    </div>
}