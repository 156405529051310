import s from "./Main.module.css"
import c from "./Courses.module.css"
import {Filter, PageTitle} from "../Modules/Modules";
import React, {useEffect, useState} from "react";
import axios from "axios";

const Courses = () => {

    const [respData, setData] = useState("")


    useEffect(() => {
        axios.get("https://dpo.fcprc.ru/api/courses").then(
            res => {
                setData(res)
            })
    }, [])

    let coursesArray = respData ? [].concat(respData.data) : []

    return (
        <div className={s.main}>
            <PageTitle title="Курсы дополнительного профессионального образования"/>
            <div className={s.newFilterPage}>
                <Filter filterTitle="Все курсы" filterArray={filterArray}/>
                <div className={s.coursePart}>
                    {respData ? coursesArray.map(el =>
                        <WebWindow el={el}/>
                    ) : <></>}
                </div>
            </div>
        </div>
    )
}
export default Courses

export const WebWindow = ({el}) => {
    return (
        <div className={c.webWindow}>
            <div className={c.courseImage}>
                <img src={el.картинка_курса} alt=""/>
            </div>
            <div className={c.word}>
                {el.направленность_курса}
            </div>
            <div className={c.courseTitle}>
                {el.заголовок}
            </div>
            <div className={c.info}>
                <span className={c.left}>
                    {el.описание} академических часов
                </span>
                <span className={c.right}>
                    {el.стоимость_курса}
                </span>
            </div>
            <div className={c.bottom}>
                <span className={c.left}>
                    <a href={el.ссылка_на_страницу}><b>Описание</b></a>
                </span>
                <span className={c.right}>
                    <a href={el.текст_курса}>Записаться на курс</a>
                </span>
            </div>
        </div>
    )
}

export const FreeWebWindow = ({el}) => {
    return (
        <div className={c.webWindow}>
            <div className={c.courseImage}>
                <img src="https://dpo.fcprc.ru/media/media/BigCourse_1mZKUfg.png" alt=""/>
            </div>
            <div className={c.word}>
                Курс повышения квалификации
            </div>
            <div className={c.courseTitle}>
                Организация подросткового центра как современного пространства для развития, социализации и самореализации подростков
            </div>
            <div className={c.info}>
                <span className={c.left}>
                    36 академических часов
                </span>
                <span className={c.right}>
                     Бюджетный
                </span>
            </div>
            <div className={c.bottom}>
                <span className={c.left}>
                    <a href="/teenagerscourse1">Описание</a>
                </span>
                <span className={c.right}>
                     <a href="/free_registration">Записаться на курс</a>
                </span>
            </div>
        </div>
    )
}

export const filterArray = [
    {
        title: "Опека и попечительство",
        url: "/courses/opeca",
    },
    {
        title: "Медиация и медиативные технологии",
        url: "/courses/mediation",
    },
    {
        title: "Профилактика девиантного поведения",
        url: "/courses/pdp",
    },
]

export const courseExample = [
    {
        img: require("../../assets/Main/another_c_ex.png"),
        title: "Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента",
        time: "36 академических часа",
        price: "",
        url: "/opecacourse1"
    },
    {
        img: require("../../assets/Main/another_c_ex.png"),
        title: "Особенности подготовки и сопровождения опекунов или попечителей несовершеннолетних",
        time: "36 академических часов",
        price: "",
        url: "/opecacourse2"
    },
    {
        img: require("../../assets/Main/course_example.png"),
        title: "Основы медиации для специалистов по работе с семьей, органов опеки и попечительства",
        time: "0 академических часов",
        price: "",
        url: "/mediationcourse1"
    },
    {
        img: require("../../assets/Main/course_example.png"),
        title: "Практикум начинающего медиатора: учимся на кейсах",
        time: "0 академических часов",
        price: "",
        url: "/mediationcourse2"
    },
    {
        img: require("../../assets/Main/course_example.png"),
        title: "Практикум профессионального медиатора: тренируемся на кейсах",
        time: "0 академических часов",
        price: "",
        url: "/mediationcourse3"
    },
    {
        img: require("../../assets/Main/course_example.png"),
        title: "Медиативно-восстановительные технологии в образовательной организации",
        time: "36 академических часов",
        price: "",
        url: "/mediationcourse4"
    },
    {
        img: require("../../assets/Main/course_example.png"),
        title: "Формирование ценностей здоровьесбережения и профилактика зависимого поведения обучающихся образовательных организаций",
        time: "20 академических часов",
        price: "",
        url: "/pdpcourse1"
    },
    {
        img: require("../../assets/Main/course_example.png"),
        title: "Психологические проблемы детско-подросткового возраста: профилактика и коррекция в общеобразовательных организациях",
        time: "20 академических часов",
        price: "",
        url: "/pdpcourse2"
    }
]