import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import authReducer from "./auth_reducer";
import {thunk} from "redux-thunk"
import tokenReducer from "./token_reducer";
import profileReducer from "./Profile_reducer";



let reducers = combineReducers({
        auth: authReducer,
        token: tokenReducer,
        profile:profileReducer,
    }
);


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
window.__store__ = store;


export default store
