import s from "./Main.module.css"


const ZayavkaConfirm = () => {

    return (
        <div className={s.main}>
            <div className={s.loginForm}>
                <h1>Обучение</h1>
                <div className={s.loginInfo} style={{textAlign: "center"}}>
                    <label>
                       Заявка была отправлена
                    </label>
                    <label>
                        <a href="/">Перейти на главную страницу</a>
                    </label>
                </div>
            </div>
        </div>
    )
}
export default ZayavkaConfirm

