import m from "../../CoursesExamples/CourseEx.module.css"
import {CourseDescription, CourseFilter, PageTitle, SubTitle} from "../../Modules/Modules";
import {TeacherBlock} from "../../CoursesExamples/CourseExample1";
import s from "../../Modules/Modules.module.css";
import {MC2Array} from "../../CoursesExamples/MediationCourse2";
import file1
    from "../../../assets/FilesForDeleteInFuture/OC1/1. ОП 36 ч. Рассмотрение споров родителей, связанных с воспитанием детей.pdf"
import file2 from "../../../assets/FilesForDeleteInFuture/OC1/2. Расписание занятий ПК-О П-2.pdf"
import file3 from "../../../assets/FilesForDeleteInFuture/OC1/3. Процедура проведения аттестации.pdf"
import pres1 from "../../../assets/FilesForDeleteInFuture/OC1/Презентация Тема 1.1. 15.04.2024.pdf"
import file4 from "../../../assets/FilesForDeleteInFuture/OC1/Презентация-Лашкул-М.В.pdf"
import iw1 from "../../../assets/FilesForDeleteInFuture/OC1/Тема 1.1 Задание для самостоятельной работы.pdf"
import iw2 from "../../../assets/FilesForDeleteInFuture/OC1/Тема 2.3 Задание для самостоятельной работы.pdf"
import pres2 from "../../../assets/FilesForDeleteInFuture/OC1/Презентация Г.В. Семья.pdf"
import pres3 from "../../../assets/FilesForDeleteInFuture/OC1/Засемкова О.Ф. - Презентация ДПО.pdf"
import pres4 from "../../../assets/FilesForDeleteInFuture/OC1/Савенко О.Е. - Презентация ДПО.pdf"
import pres5 from "../../../assets/FilesForDeleteInFuture/OC1/Статистика.pdf"
import pres6 from "../../../assets/FilesForDeleteInFuture/OC1/Презентация_Тюльканов_ДПО_2024_.pdf"

const OC1Information = () => {
    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Медиация"/>
                    <PageTitle
                        title="Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента"/>
                    <TeacherBlock name="Марина Валерьевна Лашкул"
                                  position='Заместитель директора по вопросам опеки и попечительства ФГБУ "Центр защиты прав и интересов детей"'/>
                    <p style={{fontSize: "30px"}}></p>
                    <CourseDescription title="Информация для ознакомления">
                        <CourseDesc/>
                    </CourseDescription>
                    <p style={{fontSize: "30px"}}></p>
                    <CourseDescription title="Ссылки для подключения к занятиям">
                        <CourseDesc2/>
                    </CourseDescription>
                    <p style={{fontSize: "30px"}}></p>
                    <CourseDescription title="Презентации занятий">
                        <Presentations/>
                    </CourseDescription>
                    <p style={{fontSize: "30px"}}></p>
                    <CourseDescription title="Самостоятельная работа">
                        <IndependentWork/>
                    </CourseDescription>
                </div>
                <div className={m.courseRight}>
                    <CourseFilter filterArray={MC2Array}/>
                </div>
            </div>

        </div>
    )
}
export default OC1Information

const CourseDesc = () => {
    return <div className={s.courseDescription}>
        <div className={s.ref}>
            <a href={file1}>ОП 36 ч. Рассмотрение споров родителей, связанных с воспитанием детей</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={file2}>Расписание занятий ПК-О П-2</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={file3}>Процедура
                проведения аттестации</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href="https://t.me/+K33lqrhEmIc3ZWNi">Группа в телеграм</a>
        </div>
    </div>
}

const CourseDesc2 = () => {
    return <div className={s.courseDescription}>
        <div className={s.ref}>
        Дата: 15.04.2024<br/>
            Время: 10:00 – 13:10<br/>
            <a href="https://my.mts-link.ru/j/19466565/524670032">https://my.mts-link.ru/j/19466565/524670032</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 16.04.2024<br/>
            Время: 10:00 – 13:10<br/>
            <a href="https://my.mts-link.ru/j/19466565/865673936">https://my.mts-link.ru/j/19466565/865673936</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 18.04.2024<br/>
            Время: 10:00 – 13:10<br/>
            <a href="https://my.mts-link.ru/j/19466565/441748015">https://my.mts-link.ru/j/19466565/441748015</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 22.04.2024<br/>
            Время: 10:00 –13:10<br/>
            <a href="https://my.mts-link.ru/j/19466565/1462456356">https://my.mts-link.ru/j/19466565/1462456356</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 23.04.2024<br/>
            Время: 11.00 — 14.10<br/>
            <a href="https://my.mts-link.ru/j/19466565/446474765">https://my.mts-link.ru/j/19466565/446474765</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 25.04.2024<br/>
            Время: 10:00 – 13:10<br/>
            <a href="https://my.mts-link.ru/j/19466565/851534113">https://my.mts-link.ru/j/19466565/851534113</a>
        </div>
        <p></p>

    </div>
}

const IndependentWork = () => {
    return <div className={s.courseDescription}>
        <div className={s.ref}>
            <a href={iw1}>Тема 1.1 Задание для самостоятельной работы</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={iw2}>Тема 2.3 Задание для самостоятельной работы</a>
        </div>
        <p></p>
    </div>
}

const Presentations = () => {
    return <div className={s.courseDescription}>
        <div className={s.ref}>
            <a href={pres1}>Презентация Тема 1.1. 15.04.2024</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={file4}>Презентация Лашкул М.В.</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={pres2}>Презентация Г.В. Семья</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={pres3}>Засемкова О.Ф. - Презентация ДПО</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={pres4}>Савенко О.Е. - Презентация ДПО</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={pres5}>Статистика</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={pres6}>Презентация Тюльканов - ДПО</a>
        </div>
        <p></p>
    </div>
}

