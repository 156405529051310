import s from "./Main.module.css"
import cc from "./Cabinet.module.css"
import {CourseDescription, PageTitle} from "../Modules/Modules";
import "../Modules/Avatar/Avatar.module.css"
import * as Yup from 'yup';
import {Field, Form, Formik} from "formik";
import blank2 from "../../assets/Files/Личное заявление физического лица.docx"
import blank3 from "../../assets/Files/Согласие-на-обработку-персональных-данных (2).docx"
import blank4
    from "../../assets/Files/Согласие-на-обработку-персональных-данных-разрешенных-субъектом-персональных-данных-для-распространения- (2).docx"
import blank5 from "../../assets/Files/Юр.лицо Договор об оказании платных образовательных услуг.doc"
import blank6 from "../../assets/Files/Физ.лицо Договор об оказании платных образовательных услуг.doc"
import blank7 from "../../assets/Files/Заявка о приеме на обучение от учреждения.docx"
import axios from "axios";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";


const CabinetEdit = () => {
    let navigate = useNavigate()
    const [allow, setAllow] = useState(true)
    const [check, setCheck] = useState(false)
    const [data, setData] = useState('')
    const [send, setSend] = useState('Отправить')

    useEffect(() => {
        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: 'https://dpo.fcprc.ru/api/usersdata',
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem("access")}`,
            }
        };
        axios.request(config)
            .then((response) => {
                setData(response.data)

                if (response.data.заполнение_отключить === "1") {
                    setAllow(false)
                }
                if (response.data.файл_согласия !== "" && response.data.файл_согласия !== null) {
                    setCheck(true)
                }
            })

    }, []);



    const InfoSchema = Yup.object().shape({
        firstName: Yup.string()
            .max(50, 'Превышен лимит символов!'),
        lastName: Yup.string()
            .max(50, 'Превышен лимит символов!'),
        middleName: Yup.string()
            .max(50, 'Превышен лимит символов!'),
        email: Yup.string()
            .email('Неправильный формат почты!'),
        phone: Yup.string()
            .min(11, "Введите полный номер"),
        country: Yup.string(),
        region: Yup.string(),
        birthday: Yup.string(),
        age: Yup.string(),
        city: Yup.string(),
        sex: Yup.string(),
        snils: Yup.string()
            .matches(/^\d\d\d\-\d\d\d\-\d\d\d[ \-]\d\d$/, "Неправильный формат СНИЛС"),
        toggle: Yup
            .bool().oneOf([true], "Подтвердите правильность данных!"),
        education: Yup.string(),
        qualification: Yup.string(),
        series: Yup.string(),
        docNum: Yup.string(),
        eduDate: Yup.string(),
        place: Yup.string(),
        razdel2: Yup.string()
            ,
        razdel3: Yup.string()
            ,
        index: Yup.string()
            ,
        mail_city: Yup.string()
            ,
        street: Yup.string(),
        number: Yup.string(),
        korpus: Yup.string(),
        building: Yup.string(),
        apartment: Yup.string(),
        // file2: Yup.mixed().required('Этот файл обязателен!'),
        // file3: Yup.mixed().required('Этот файл обязателен!'),
        // file4: Yup.mixed().required('Этот файл обязателен!'),
        // file7: Yup.mixed().required('Этот файл обязателен!'),
        // file8: Yup.mixed().required('Этот файл обязателен!'),
        // file9: Yup.mixed().required('Этот файл обязателен!'),
    });
    const [file1, setFile1] = useState("")
    const [file2, setFile2] = useState("")
    const [file3, setFile3] = useState("")
    const [file4, setFile4] = useState("")
    const [file5, setFile5] = useState("")
    const [file6, setFile6] = useState("")
    const [file7, setFile7] = useState("")
    const [file8, setFile8] = useState("")
    const [file9, setFile9] = useState("")
    const [file10, setFile10] = useState("")
    const [dogovor2, setDogovor2] = useState("")
    const [zayavlenie2, setZayavlenie2] = useState("")
    const [pasport2, setPasport2] = useState("")
    const [doc2, setDoc2] = useState("")
    const [smena2, setSmena2] = useState("")

    const onMainPhotoSelected1 = (e) => {
        if (e.target.files.length) {
            setFile1(e.target.files[0])
        }
    }
    const onMainPhotoSelected2 = (e) => {
        if (e.target.files.length) {
            setFile2(e.target.files[0])
        }
    }
    const onMainPhotoSelected3 = (e) => {
        if (e.target.files.length) {
            setFile3(e.target.files[0])
        }
    }
    const onMainPhotoSelected4 = (e) => {
        if (e.target.files.length) {
            setFile4(e.target.files[0])
        }
    }
    const onMainPhotoSelected5 = (e) => {
        if (e.target.files.length) {
            setFile5(e.target.files[0])
        }
    }
    const onMainPhotoSelected6 = (e) => {
        if (e.target.files.length) {
            setFile6(e.target.files[0])
        }
    }
    const onMainPhotoSelected7 = (e) => {
        if (e.target.files.length) {
            setFile7(e.target.files[0])
        }
    }
    const onMainPhotoSelected8 = (e) => {
        if (e.target.files.length) {
            setFile8(e.target.files[0])
        }
    }
    const onMainPhotoSelected9 = (e) => {
        if (e.target.files.length) {
            setFile9(e.target.files[0])
        }
    }
    const onMainPhotoSelectedDogovor = (e) => {
        if (e.target.files.length) {
            setDogovor2(e.target.files[0])
        }
    }
    const onMainPhotoSelectedZayavlenie = (e) => {
        if (e.target.files.length) {
            setZayavlenie2(e.target.files[0])
        }
    }
    const onMainPhotoSelectedPass = (e) => {
        if (e.target.files.length) {
            setPasport2(e.target.files[0])
        }
    }
    const onMainPhotoSelectedDoc = (e) => {
        if (e.target.files.length) {
            setDoc2(e.target.files[0])
        }
    }
    const onMainPhotoSelected10 = (e) => {
        if (e.target.files.length) {
            setFile10(e.target.files[0])
        }
    }
    const onMainPhotoSelectedSmena = (e) => {
        if (e.target.files.length) {
            setSmena2(e.target.files[0])
        }
    }
    return (
        <div className={s.main}>

            <div className={s.cabinetTitle}>
                <div className={s.left}>
                    <PageTitle title="Редактирование данных"/>
                </div>
            </div>
            {
                !allow &&
                <div
                    style={{paddingBottom: "15px", fontSize: "30px", fontWeight: "bold", color: "red"}}>Ваши
                    данные на обработке! Повторная отправка недоступна</div>
            }
            {
                (check && allow) &&
                <div
                    style={{paddingBottom: "15px", fontSize: "30px", fontWeight: "bold", color: "red"}}>Ваши
                    данные были отправлены и находятся на обработке!</div>
            }
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: data.имя,
                    lastName: data.фамилия,
                    middleName: data.отчество,
                    sex: data.пол,
                    email: data.почта,
                    phone: data.телефон,
                    country: data.страна,
                    region: data.регион,
                    birthday: data.дата_рождения,
                    age: data.возраст,
                    city: data.город,
                    snils: data.СНИЛС,
                    education: data.уровень_обр,
                    qualification: data.квалификация_дип,
                    series: data.серия_док,
                    surname: data.фамилия_диплом,
                    docNum: data.номер_док,
                    eduDate: data.дата_выд,
                    place: data.место_работы,
                    position: data.должность,
                    razdel2: data.раздел2,
                    razdel3: data.раздел3,
                    index: data.индекс,
                    mail_city: data.нас_пункт,
                    street: data.улица,
                    number: data.номер_дома,
                    korpus: data.корпус,
                    building: data.строение,
                    apartment: data.квартира,
                    file1: data.файл_оплаты,
                    file2: data.заявление,
                    file3: data.файл_согласия,
                    file4: data.файл_согласия_рег,
                    file5: data.договор_платных_услуг,
                    file6: data.квитанция,
                    file7: data.фото_снилс,
                    file8: data.фото_паспорт,
                    file9: data.Документ_фото,
                    file10: data.Смена_фото,
                    dogovor2: data.договор_платных_услуг2,
                    zayavlenie2: data.заявление2,
                    passport2: data.фото_паспорт2,
                    smena2: data.Смена_фото2,
                    doc2: data.Документ_фото2
                }}
                onSubmit={(values, {setSubmitting}) => {

                    let data1 = new FormData();
                    data1.append('имя', values.firstName)
                    data1.append('first_name', values.firstName)
                    data1.append('фамилия', values.lastName)
                    data1.append('last_name', values.lastName)
                    data1.append('отчество', values.middleName)
                    data1.append('телефон', values.phone)
                    data1.append('почта', values.email)
                    data1.append('должность', values.position)
                    data1.append('регион', values.region)
                    data1.append('СНИЛС', values.snils)
                    data1.append('город', values.city)
                    data1.append('возраст', values.age)
                    data1.append('дата_рождения', values.birthday)
                    data1.append('пол', values.sex)
                    data1.append('страна', values.country)
                    data1.append('уровень_обр', values.education)
                    data1.append('номер_док', values.docNum)
                    data1.append('дата_выд', values.eduDate)
                    data1.append('квалификация_дип', values.qualification)
                    data1.append('серия_док', values.series)
                    data1.append('фамилия_диплом', values.surname)
                    data1.append('место_работы', values.place)
                    data1.append('индекс', values.index)
                    data1.append('улица', values.street)
                    data1.append('нас_пункт', values.mail_city)
                    data1.append('номер_дома', values.number)
                    data1.append('корпус', values.korpus)
                    data1.append('строение', values.building)
                    data1.append('квартира', values.apartment)
                    data1.append('раздел2', values.razdel2)
                    data1.append('раздел3', values.razdel3)
                    data1.append('файл_оплаты', file1)
                    data1.append('файл_согласия', file3)
                    data1.append('файл_согласия_рег', file4)
                    data1.append('договор_платных_услуг', file5)
                    data1.append('заявление', file2)
                    data1.append('квитанция', file6)
                    data1.append('фото_снилс', file7)
                    data1.append('фото_паспорт', file8)
                    data1.append('Документ_фото', file9)
                    data1.append('Смена_фото', file10)
                    data1.append('договор_платных_услуг2', dogovor2)
                    data1.append('заявление2', zayavlenie2)
                    data1.append('Смена_фото2', smena2)
                    data1.append('фото_паспорт2', pasport2)
                    data1.append('Документ_фото2', doc2)

                    let config = {
                        method: 'patch',
                        maxBodyLength: Infinity,

                        url: 'https://dpo.fcprc.ru/api/usersdata',
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'Authorization': `Bearer ${localStorage.getItem("access")}`,
                        },
                        data: data1
                    };
                    setSend('Отправка...')
                    axios
                        .request(config)
                        .then((response) => {

                            setAllow(false)
                            setSend('Отправить')
                            navigate("/cabinet")
                        })

                }}
                validationSchema={InfoSchema}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <CourseDescription title="Основные данные">
                            <div className={cc.editInfo}>
                                <div className={cc.formStyle}>
                                    <label className={cc.item}>
                                        <div>
                                            Имя <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.имя !== "")}
                                                style={isWrong(errors.firstName, touched.firstName)}
                                                name="firstName"/>
                                        </div>
                                        {errors.firstName && touched.firstName ? (
                                            <div className={cc.wrong}>{errors.firstName}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Фамилия <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.фамилия !== "")}
                                                style={isWrong(errors.lastName, touched.lastName)}
                                                name="lastName"/>
                                        </div>
                                        {errors.lastName && touched.lastName ? (
                                            <div className={cc.wrong}>{errors.lastName}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Отчество <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.отчество !== "")}
                                                style={isWrong(errors.middleName, touched.middleName)}
                                                name="middleName"/>
                                        </div>
                                        {errors.middleName && touched.middleName ? (
                                            <div className={cc.wrong}>{errors.middleName}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Пол <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   required={!(data && data.пол !== "")}
                                                   style={isWrong(errors.sex, touched.sex)}
                                                   name="sex">
                                                <option disabled selected value="">Выберите пол</option>
                                                <option value="Муж">Мужской</option>
                                                <option value="Жен">Женский</option>
                                            </Field>
                                        </div>
                                        {errors.sex && touched.sex ? (
                                            <div className={cc.wrong}>{errors.sex}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            E-mail <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.почта !== "")}
                                                type="email"
                                                style={isWrong(errors.email, touched.email)}
                                                name="email"/>
                                        </div>
                                        {errors.email && touched.email ? (
                                            <div className={cc.wrong}>{errors.email}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Телефон <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.телефон !== "")}
                                                type="tel"
                                                style={isWrong(errors.phone, touched.phone)}
                                                name="phone"/>
                                        </div>
                                        {errors.phone && touched.phone ? (
                                            <div className={cc.wrong}>{errors.phone}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Дата рождения <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.дата_рождения !== "")}
                                                type="date"
                                                style={isWrong(errors.birthday, touched.birthday)}
                                                name="birthday"/>
                                        </div>
                                        {errors.birthday && touched.birthday ? (
                                            <div className={cc.wrong}>{errors.birthday}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Возраст <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   required={!(data && data.возраст !== "")}
                                                   style={isWrong(errors.age, touched.age)}
                                                   name="age">
                                                <option disabled selected value="">Выберите возраст</option>
                                                <option value="Моложе 25 лет">Моложе 25 лет</option>
                                                <option value="25-29">25-29</option>
                                                <option value="30-34">30-34</option>
                                                <option value="35-39">35-39</option>
                                                <option value="40-44">40-44</option>
                                                <option value="45-49">45-49</option>
                                                <option value="50-54">50-54</option>
                                                <option value="55-59">55-59</option>
                                                <option value="60-64">60-64</option>
                                                <option value="65 и более">65 и более</option>
                                            </Field>
                                        </div>
                                        {errors.age && touched.age ? (
                                            <div className={cc.wrong}>{errors.age}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Страна <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   required={!(data && data.страна !== "")}
                                                   style={isWrong(errors.country, touched.country)}
                                                   name="country">
                                                <option disabled selected value="">Выберите страну</option>
                                                <option value="Австралия">Австралия</option>
                                                <option value="Австрия">Австрия</option>
                                                <option value="Азербайджан">Азербайджан</option>
                                                <option value="Албания">Албания</option>
                                                <option value="Алжир">Алжир</option>
                                                <option value="Американское Самоа">Американское Самоа</option>
                                                <option value="Ангилья">Ангилья</option>
                                                <option value="Ангола">Ангола</option>
                                                <option value="Андорра">Андорра</option>
                                                <option value="Антигуа и Барбуда">Антигуа и Барбуда</option>
                                                <option value="Аргентина">Аргентина</option>
                                                <option value="Армения">Армения</option>
                                                <option value="Аруба">Аруба</option>
                                                <option value="Афганистан">Афганистан</option>
                                                <option value="Багамы">Багамы</option>
                                                <option value="Бангладеш">Бангладеш</option>
                                                <option value="Барбадос">Барбадос</option>
                                                <option value="Бахрейн">Бахрейн</option>
                                                <option value="Беларусь">Беларусь</option>
                                                <option value="Белиз">Белиз</option>
                                                <option value="Бельгия">Бельгия</option>
                                                <option value="Бенин">Бенин</option>
                                                <option value="Бермуды">Бермуды</option>
                                                <option value="Болгария">Болгария</option>
                                                <option value="Боливия">Боливия</option>
                                                <option value="Бонайре, Синт-Эстатиус и Саба">Бонайре, Синт-Эстатиус и
                                                    Саба
                                                </option>
                                                <option value="Босния и Герцеговина">Босния и Герцеговина</option>
                                                <option value="Ботсвана">Ботсвана</option>
                                                <option value="Бразилия">Бразилия</option>
                                                <option value="Бруней-Даруссалам">Бруней-Даруссалам</option>
                                                <option value="Буркина-Фасо">Буркина-Фасо</option>
                                                <option value="Бурунди">Бурунди</option>
                                                <option value="Бутан">Бутан</option>
                                                <option value="Вануату">Вануату</option>
                                                <option value="Ватикан">Ватикан</option>
                                                <option value="Великобритания">Великобритания</option>
                                                <option value="Венгрия">Венгрия</option>
                                                <option value="Венесуэла">Венесуэла</option>
                                                <option value="Виргинские острова, Британские">Виргинские острова,
                                                    Британские
                                                </option>
                                                <option value="Виргинские острова, США">Виргинские острова, США</option>
                                                <option value="Восточный Тимор">Восточный Тимор</option>
                                                <option value="Вьетнам">Вьетнам</option>
                                                <option value="Габон">Габон</option>
                                                <option value="Гаити">Гаити</option>
                                                <option value="Гайана">Гайана</option>
                                                <option value="Гамбия">Гамбия</option>
                                                <option value="Гана">Гана</option>
                                                <option value="Гваделупа">Гваделупа</option>
                                                <option value="Гватемала">Гватемала</option>
                                                <option value="Гвинея">Гвинея</option>
                                                <option value="Гвинея-Бисау">Гвинея-Бисау</option>
                                                <option value="Германия">Германия</option>
                                                <option value="Гибралтар">Гибралтар</option>
                                                <option value="Гондурас">Гондурас</option>
                                                <option value="Гонконг">Гонконг</option>
                                                <option value="Гренада">Гренада</option>
                                                <option value="Гренландия">Гренландия</option>
                                                <option value="Греция">Греция</option>
                                                <option value="Грузия">Грузия</option>
                                                <option value="Гуам">Гуам</option>
                                                <option value="Дания">Дания</option>
                                                <option value="Джибути">Джибути</option>
                                                <option value="Доминика">Доминика</option>
                                                <option value="Доминиканская Республика">Доминиканская Республика
                                                </option>
                                                <option value="Египет">Египет</option>
                                                <option value="Замбия">Замбия</option>
                                                <option value="Западная Сахара">Западная Сахара</option>
                                                <option value="Зимбабве">Зимбабве</option>
                                                <option value="Израиль">Израиль</option>
                                                <option value="Индия">Индия</option>
                                                <option value="Индонезия">Индонезия</option>
                                                <option value="Иордания">Иордания</option>
                                                <option value="Ирак">Ирак</option>
                                                <option value="Иран">Иран</option>
                                                <option value="Ирландия">Ирландия</option>
                                                <option value="Исландия">Исландия</option>
                                                <option value="Испания">Испания</option>
                                                <option value="Италия">Италия</option>
                                                <option value="Йемен">Йемен</option>
                                                <option value="Кабо-Верде">Кабо-Верде</option>
                                                <option value="Казахстан">Казахстан</option>
                                                <option value="Камбоджа">Камбоджа</option>
                                                <option value="Камерун">Камерун</option>
                                                <option value="Канада">Канада</option>
                                                <option value="Катар">Катар</option>
                                                <option value="Кения">Кения</option>
                                                <option value="Кипр">Кипр</option>
                                                <option value="Кирибати">Кирибати</option>
                                                <option value="Китай">Китай</option>
                                                <option value="Колумбия">Колумбия</option>
                                                <option value="Коморы">Коморы</option>
                                                <option value="Конго">Конго</option>
                                                <option value="Конго, демократическая республика">Конго, демократическая
                                                    республика
                                                </option>
                                                <option value="Коста-Рика">Коста-Рика</option>
                                                <option value="Кот д`Ивуар">Кот д`Ивуар</option>
                                                <option value="Куба">Куба</option>
                                                <option value="Кувейт">Кувейт</option>
                                                <option value="Кыргызстан">Кыргызстан</option>
                                                <option value="Кюрасао">Кюрасао</option>
                                                <option value="Лаос">Лаос</option>
                                                <option value="Латвия">Латвия</option>
                                                <option value="Лесото">Лесото</option>
                                                <option value="Либерия">Либерия</option>
                                                <option value="Ливан">Ливан</option>
                                                <option value="Ливия">Ливия</option>
                                                <option value="Литва">Литва</option>
                                                <option value="Лихтенштейн">Лихтенштейн</option>
                                                <option value="Люксембург">Люксембург</option>
                                                <option value="Маврикий">Маврикий</option>
                                                <option value="Мавритания">Мавритания</option>
                                                <option value="Мадагаскар">Мадагаскар</option>
                                                <option value="Макао">Макао</option>
                                                <option value="Македония">Македония</option>
                                                <option value="Малави">Малави</option>
                                                <option value="Малайзия">Малайзия</option>
                                                <option value="Мали">Мали</option>
                                                <option value="Мальдивы">Мальдивы</option>
                                                <option value="Мальта">Мальта</option>
                                                <option value="Марокко">Марокко</option>
                                                <option value="Мартиника">Мартиника</option>
                                                <option value="Маршалловы Острова">Маршалловы Острова</option>
                                                <option value="Мексика">Мексика</option>
                                                <option value="Микронезия, федеративные штаты">Микронезия, федеративные
                                                    штаты
                                                </option>
                                                <option value="Мозамбик">Мозамбик</option>
                                                <option value="Молдова">Молдова</option>
                                                <option value="Монако">Монако</option>
                                                <option value="Монголия">Монголия</option>
                                                <option value="Монтсеррат">Монтсеррат</option>
                                                <option value="Мьянма">Мьянма</option>
                                                <option value="Намибия">Намибия</option>
                                                <option value="Науру">Науру</option>
                                                <option value="Непал">Непал</option>
                                                <option value="Нигер">Нигер</option>
                                                <option value="Нигерия">Нигерия</option>
                                                <option value="Нидерланды">Нидерланды</option>
                                                <option value="Никарагуа">Никарагуа</option>
                                                <option value="Ниуэ">Ниуэ</option>
                                                <option value="Новая Зеландия">Новая Зеландия</option>
                                                <option value="Новая Каледония">Новая Каледония</option>
                                                <option value="Норвегия">Норвегия</option>
                                                <option value="Объединенные Арабские Эмираты">Объединенные Арабские
                                                    Эмираты
                                                </option>
                                                <option value="Оман">Оман</option>
                                                <option value="Остров Мэн">Остров Мэн</option>
                                                <option value="Остров Норфолк">Остров Норфолк</option>
                                                <option value="Острова Кайман">Острова Кайман</option>
                                                <option value="Острова Кука">Острова Кука</option>
                                                <option value="Острова Теркс и Кайкос">Острова Теркс и Кайкос</option>
                                                <option value="Пакистан">Пакистан</option>
                                                <option value="Палау">Палау</option>
                                                <option value="Палестинская автономия">Палестинская автономия</option>
                                                <option value="Панама">Панама</option>
                                                <option value="Папуа - Новая Гвинея">Папуа - Новая Гвинея</option>
                                                <option value="Парагвай">Парагвай</option>
                                                <option value="Перу">Перу</option>
                                                <option value="Питкерн">Питкерн</option>
                                                <option value="Польша">Польша</option>
                                                <option value="Португалия">Португалия</option>
                                                <option value="Пуэрто-Рико">Пуэрто-Рико</option>
                                                <option value="Реюньон">Реюньон</option>
                                                <option value="Россия">Россия</option>
                                                <option value="Руанда">Руанда</option>
                                                <option value="Румыния">Румыния</option>
                                                <option value="США">США</option>
                                                <option value="Сальвадор">Сальвадор</option>
                                                <option value="Самоа">Самоа</option>
                                                <option value="Сан-Марино">Сан-Марино</option>
                                                <option value="Сан-Томе и Принсипи">Сан-Томе и Принсипи</option>
                                                <option value="Саудовская Аравия">Саудовская Аравия</option>
                                                <option value="Свазиленд">Свазиленд</option>
                                                <option value="Святая Елена">Святая Елена</option>
                                                <option value="Северная Корея">Северная Корея</option>
                                                <option value="Северные Марианские острова">Северные Марианские
                                                    острова
                                                </option>
                                                <option value="Сейшелы">Сейшелы</option>
                                                <option value="Сенегал">Сенегал</option>
                                                <option value="Сент-Винсент">Сент-Винсент</option>
                                                <option value="Сент-Китс и Невис">Сент-Китс и Невис</option>
                                                <option value="Сент-Люсия">Сент-Люсия</option>
                                                <option value="Сент-Пьер и Микелон">Сент-Пьер и Микелон</option>
                                                <option value="Сербия">Сербия</option>
                                                <option value="Сингапур">Сингапур</option>
                                                <option value="Синт-Мартен">Синт-Мартен</option>
                                                <option value="Сирийская Арабская Республика">Сирийская Арабская
                                                    Республика
                                                </option>
                                                <option value="Словакия">Словакия</option>
                                                <option value="Словения">Словения</option>
                                                <option value="Соломоновы Острова">Соломоновы Острова</option>
                                                <option value="Сомали">Сомали</option>
                                                <option value="Судан">Судан</option>
                                                <option value="Суринам">Суринам</option>
                                                <option value="Сьерра-Леоне">Сьерра-Леоне</option>
                                                <option value="Таджикистан">Таджикистан</option>
                                                <option value="Таиланд">Таиланд</option>
                                                <option value="Тайвань">Тайвань</option>
                                                <option value="Танзания">Танзания</option>
                                                <option value="Того">Того</option>
                                                <option value="Токелау">Токелау</option>
                                                <option value="Тонга">Тонга</option>
                                                <option value="Тринидад и Тобаго">Тринидад и Тобаго</option>
                                                <option value="Тувалу">Тувалу</option>
                                                <option value="Тунис">Тунис</option>
                                                <option value="Туркменистан">Туркменистан</option>
                                                <option value="Турция">Турция</option>
                                                <option value="Уганда">Уганда</option>
                                                <option value="Узбекистан">Узбекистан</option>
                                                <option value="Украина">Украина</option>
                                                <option value="Уоллис и Футуна">Уоллис и Футуна</option>
                                                <option value="Уругвай">Уругвай</option>
                                                <option value="Фарерские острова">Фарерские острова</option>
                                                <option value="Фиджи">Фиджи</option>
                                                <option value="Филиппины">Филиппины</option>
                                                <option value="Финляндия">Финляндия</option>
                                                <option value="Фолклендские острова">Фолклендские острова</option>
                                                <option value="Франция">Франция</option>
                                                <option value="Французская Гвиана">Французская Гвиана</option>
                                                <option value="Французская Полинезия">Французская Полинезия</option>
                                                <option value="Хорватия">Хорватия</option>
                                                <option value="Центрально-Африканская Республика">Центрально-Африканская
                                                    Республика
                                                </option>
                                                <option value="Чад">Чад</option>
                                                <option value="Черногория">Черногория</option>
                                                <option value="Чехия">Чехия</option>
                                                <option value="Чили">Чили</option>
                                                <option value="Швейцария">Швейцария</option>
                                                <option value="Швеция">Швеция</option>
                                                <option value="Шпицберген и Ян Майен">Шпицберген и Ян Майен</option>
                                                <option value="Шри-Ланка">Шри-Ланка</option>
                                                <option value="Эквадор">Эквадор</option>
                                                <option value="Экваториальная Гвинея">Экваториальная Гвинея</option>
                                                <option value="Эритрея">Эритрея</option>
                                                <option value="Эстония">Эстония</option>
                                                <option value="Эфиопия">Эфиопия</option>
                                                <option value="Южная Корея">Южная Корея</option>
                                                <option value="Южно-Африканская Республика">Южно-Африканская
                                                    Республика
                                                </option>
                                                <option value="Южный Судан">Южный Судан</option>
                                                <option value="Ямайка">Ямайка</option>
                                                <option value="Япония">Япония</option>
                                            </Field>
                                        </div>
                                        {errors.country && touched.country ? (
                                            <div className={cc.wrong}>{errors.country}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Регион (для России)
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   style={isWrong(errors.region, touched.region)}
                                                   name="region">
                                                <option value="">Выберите регион</option>
                                                <option value="Алтайский край">Алтайский край</option>
                                                <option value="Амурская область">Амурская область</option>
                                                <option value="Архангельская область">Архангельская область</option>
                                                <option value="Астраханская область">Астраханская область</option>
                                                <option value="Белгородская область">Белгородская область</option>
                                                <option value="Брянская область">Брянская область</option>
                                                <option value="Владимирская область">Владимирская область</option>
                                                <option value="Волгоградская область">Волгоградская область</option>
                                                <option value="Вологодская область">Вологодская область</option>
                                                <option value="Воронежская область">Воронежская область</option>
                                                <option value="Донецкая Народная Республика">Донецкая Народная
                                                    Республика
                                                </option>
                                                <option value="Еврейская автономная область">Еврейская автономная
                                                    область
                                                </option>
                                                <option value="Забайкальский край">Забайкальский край</option>
                                                <option value="Запорожская область">Запорожская область</option>
                                                <option value="Ивановская область">Ивановская область</option>
                                                <option value="Иркутская область">Иркутская область</option>
                                                <option value="Кабардино-Балкарская Республика">Кабардино-Балкарская
                                                    Республика
                                                </option>
                                                <option value="Калининградская область">Калининградская область</option>
                                                <option value="Калужская область">Калужская область</option>
                                                <option value="Камчатский край">Камчатский край</option>
                                                <option value="Карачаево-Черкесская Республика">Карачаево-Черкесская
                                                    Республика
                                                </option>
                                                <option value="Кемеровская область">Кемеровская область</option>
                                                <option value="Кировская область">Кировская область</option>
                                                <option value="Костромская область">Костромская область</option>
                                                <option value="Краснодарский край">Краснодарский край</option>
                                                <option value="Красноярский край">Красноярский край</option>
                                                <option value="Крым">Крым</option>
                                                <option value="Курганская область">Курганская область</option>
                                                <option value="Курская область">Курская область</option>
                                                <option value="Ленинградская область">Ленинградская область</option>
                                                <option value="Липецкая область">Липецкая область</option>
                                                <option value="Луганская Народная Республика">Луганская Народная
                                                    Республика
                                                </option>
                                                <option value="Магаданская область">Магаданская область</option>
                                                <option value="Москва">Москва</option>
                                                <option value="Московская область">Московская область</option>
                                                <option value="Мурманская область">Мурманская область</option>
                                                <option value="Ненецкий автономный округ">Ненецкий автономный округ
                                                </option>
                                                <option value="Нижегородская область">Нижегородская область</option>
                                                <option value="Новгородская область">Новгородская область</option>
                                                <option value="Новосибирская область">Новосибирская область</option>
                                                <option value="Омская область">Омская область</option>
                                                <option value="Оренбургская область">Оренбургская область</option>
                                                <option value="Орловская область">Орловская область</option>
                                                <option value="Пензенская область">Пензенская область</option>
                                                <option value="Пермский край">Пермский край</option>
                                                <option value="Приморский край">Приморский край</option>
                                                <option value="Псковская область">Псковская область</option>
                                                <option value="Республика Адыгея">Республика Адыгея</option>
                                                <option value="Республика Алтай">Республика Алтай</option>
                                                <option value="Республика Башкортостан">Республика Башкортостан</option>
                                                <option value="Республика Бурятия">Республика Бурятия</option>
                                                <option value="Республика Дагестан">Республика Дагестан</option>
                                                <option value="Республика Ингушетия">Республика Ингушетия</option>
                                                <option value="Республика Калмыкия">Республика Калмыкия</option>
                                                <option value="Республика Карелия">Республика Карелия</option>
                                                <option value="Республика Коми">Республика Коми</option>
                                                <option value="Республика Марий Эл">Республика Марий Эл</option>
                                                <option value="Республика Мордовия">Республика Мордовия</option>
                                                <option value="Республика Саха (Якутия)">Республика Саха (Якутия)
                                                </option>
                                                <option value="Республика Северная Осетия — Алания">Республика Северная
                                                    Осетия —
                                                    Алания
                                                </option>
                                                <option value="Республика Татарстан">Республика Татарстан</option>
                                                <option value="Республика Тыва">Республика Тыва</option>
                                                <option value="Республика Хакасия">Республика Хакасия</option>
                                                <option value="Ростовская область">Ростовская область</option>
                                                <option value="Рязанская область">Рязанская область</option>
                                                <option value="Самарская область">Самарская область</option>
                                                <option value="Санкт-Петербург">Санкт-Петербург</option>
                                                <option value="Саратовская область">Саратовская область</option>
                                                <option value="Сахалинская область">Сахалинская область</option>
                                                <option value="Свердловская область">Свердловская область</option>
                                                <option value="Севастополь">Севастополь</option>
                                                <option value="Смоленская область">Смоленская область</option>
                                                <option value="Ставропольский край">Ставропольский край</option>
                                                <option value="Тамбовская область">Тамбовская область</option>
                                                <option value="Тверская область">Тверская область</option>
                                                <option value="Томская область">Томская область</option>
                                                <option value="Тульская область">Тульская область</option>
                                                <option value="Тюменская область">Тюменская область</option>
                                                <option value="Удмуртская Республика">Удмуртская Республика</option>
                                                <option value="Ульяновская область">Ульяновская область</option>
                                                <option value="Хабаровский край">Хабаровский край</option>
                                                <option value="Ханты-Мансийский автономный округ —
                                            Югра">Ханты-Мансийский автономный округ —
                                                    Югра
                                                </option>
                                                <option value="Херсонская область">Херсонская область</option>
                                                <option value="Челябинская область">Челябинская область</option>
                                                <option value="Чеченская Республика">Чеченская Республика</option>
                                                <option value="Чувашская Республика">Чувашская Республика</option>
                                                <option value="Чукотский
                                            автономный
                                            округ">Чукотский
                                                    автономный
                                                    округ
                                                </option>
                                                <option value="Ямало-Ненецкий автономный округ">Ямало-Ненецкий
                                                    автономный округ
                                                </option>
                                                <option value="Ярославская область">Ярославская область</option>
                                            </Field>
                                        </div>
                                        {errors.region && touched.region ? (
                                            <div className={cc.wrong}>{errors.region}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Город <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.город !== "")}
                                                type="text"
                                                style={isWrong(errors.city, touched.city)}
                                                name="city"/>
                                        </div>
                                        {errors.city && touched.city ? (
                                            <div className={cc.wrong}>{errors.city}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            СНИЛС <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.СНИЛС !== "")}
                                                type="text"
                                                style={isWrong(errors.snils, touched.snils)}
                                                name="snils"/>
                                        </div>
                                        {errors.snils && touched.snils ? (
                                            <div className={cc.wrong}>{errors.snils}</div>
                                        ) : null}
                                    </label>
                                </div>
                            </div>
                        </CourseDescription>
                        <CourseDescription title="Образование">
                            <div className={cc.editInfo}>
                                <div className={cc.formStyle}>
                                    <label className={cc.item}>
                                        <div>
                                            Уровень образования <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   required={!(data && data.уровень_обр !== "")}
                                                   style={isWrong(errors.education, touched.education)}
                                                   name="education">
                                                <option disabled selected value="">Выберите уровень образования</option>
                                                <option value="Высшее профессиональное образование">Высшее
                                                    профессиональное
                                                    образование
                                                </option>
                                                <option value="Среднее профессиональное образование">Среднее
                                                    профессиональное
                                                    образование
                                                </option>
                                                <option value="Справка">Справка</option>
                                            </Field>
                                        </div>
                                        {errors.education && touched.education ? (
                                            <div className={cc.wrong}>{errors.education}</div>
                                        ) : null}
                                    </label>


                                    <label className={cc.item}>
                                        <div>
                                            Квалификация по диплому <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.квалификация_дип !== "")}
                                                style={isWrong(errors.qualification, touched.qualification)}
                                                name="qualification"/>
                                        </div>
                                        {errors.qualification && touched.qualification ? (
                                            <div className={cc.wrong}>{errors.qualification}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Фамилия, указанная в дипломе <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.фамилия_диплом !== "")}
                                                style={isWrong(errors.surname, touched.surname)}
                                                name="surname"/>
                                        </div>
                                        {errors.surname && touched.surname ? (
                                            <div className={cc.wrong}>{errors.surname}</div>
                                        ) : null}
                                    </label>


                                    <label className={cc.item}>
                                        <div>
                                            Серия документа <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.серия_док !== "")}
                                                style={isWrong(errors.series, touched.series)}
                                                name="series"/>
                                        </div>
                                        {errors.series && touched.series ? (
                                            <div className={cc.wrong}>{errors.series}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Номер документа <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.номер_док !== "")}
                                                style={isWrong(errors.docNum, touched.docNum)}
                                                name="docNum"/>
                                        </div>
                                        {errors.docNum && touched.docNum ? (
                                            <div className={cc.wrong}>{errors.docNum}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Дата выдачи <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.дата_выд !== "")}
                                                type="date"
                                                style={isWrong(errors.eduDate, touched.eduDate)}
                                                name="eduDate"/>
                                        </div>
                                        {errors.eduDate && touched.eduDate ? (
                                            <div className={cc.wrong}>{errors.eduDate}</div>
                                        ) : null}
                                    </label>

                                </div>
                            </div>
                        </CourseDescription>
                        <CourseDescription title="Работа">
                            <div className={cc.editInfo}>
                                <div className={cc.formStyle}>
                                    <label className={cc.item}>
                                        <div>
                                            Место работы <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.место_работы !== "")}
                                                style={isWrong(errors.place, touched.place)}
                                                name="place"/>
                                        </div>
                                        {errors.place && touched.place ? (
                                            <div className={cc.wrong}>{errors.place}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Должность <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.должность !== "")}
                                                style={isWrong(errors.position, touched.position)}
                                                name="position"/>
                                        </div>
                                        {errors.position && touched.position ? (
                                            <div className={cc.wrong}>{errors.position}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Раздел 2 <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   required={!(data && data.раздел2 !== "")}
                                                   style={isWrong(errors.razdel2, touched.razdel2)}
                                                   name="razdel2">
                                                <option disabled selected value="">Выберите подходящее</option>
                                                <optgroup label="работник предприятия и организации">
                                                    <option
                                                        value="Работник предприятия и организации: руководитель организации и предприятия">руководитель
                                                        организации и предприятия
                                                    </option>
                                                    <option value="Работник предприятия и организации: нет">нет</option>
                                                </optgroup>
                                                <optgroup label="работник образовательной организации">
                                                    <option
                                                        value="работник образовательной организации: руководитель">руководитель
                                                        образовательной организации
                                                    </option>
                                                    <option
                                                        value="работник образовательной организации: не руководитель">нет
                                                    </option>
                                                </optgroup>
                                                <optgroup
                                                    label="преподаватель образовательных организаций, педагогический работник">
                                                    <option
                                                        value="преподаватель образовательных организаций, педагогический работник дошкольных образовательных организаций">дошкольных
                                                        образовательных организаций
                                                    </option>
                                                    <option
                                                        value="преподаватель образовательных организаций, педагогический работник общеобразовательных организаций">общеобразовательных
                                                        организаций
                                                    </option>
                                                    <option
                                                        value="преподаватель образовательных организаций, педагогический работник профессиональных образовательных организаций">профессиональных
                                                        образовательных организаций
                                                    </option>
                                                    <option
                                                        value="преподаватель образовательных организаций, педагогический работник образовательных организаций высшего образования">образовательных
                                                        организаций высшего образования
                                                    </option>
                                                    <option
                                                        value="преподаватель образовательных организаций, педагогический работник дополнительного профессионального образования">дополнительного
                                                        профессионального образования
                                                    </option>
                                                    <option
                                                        value="преподаватель образовательных организаций, педагогический работник дополнительного образования">дополнительного
                                                        образования
                                                    </option>
                                                </optgroup>
                                                <optgroup
                                                    label="лицо, замещающее государственную должность и должность государственной гражданской службы">
                                                    <option
                                                        value="лицо, замещающее государственную должность и должность государственной гражданской службы: руководитель или заместитель руководителя">руководитель
                                                        или заместитель руководителя
                                                    </option>
                                                    <option
                                                        value="лицо, замещающее государственную должность и должность государственной гражданской службы: нет">нет
                                                    </option>
                                                </optgroup>
                                                <optgroup
                                                    label="лицо, замещающее муниципальные должности и должности муниципальной службы">
                                                    <option
                                                        value="лицо, замещающее муниципальные должности и должности муниципальной службы">лицо,
                                                        замещающее муниципальные должности и должности муниципальной
                                                        службы
                                                    </option>
                                                </optgroup>
                                                <optgroup label="лицо, уволенное с военной службы">
                                                    <option value="лицо, уволенное с военной службы">лицо, уволенное с
                                                        военной
                                                        службы
                                                    </option>
                                                </optgroup>
                                                <optgroup
                                                    label="незанятое лицо по направлению службы занятости">
                                                    <option
                                                        value="незанятое лицо по направлению службы занятости: безработные">безработные
                                                    </option>
                                                    <option
                                                        value="незанятое лицо по направлению службы занятости: нет">нет
                                                    </option>
                                                </optgroup>
                                                <optgroup label="студент">
                                                    <option
                                                        value="студент, осваивающий программы среднего профессионального образования">
                                                        студент, осваивающий программы среднего профессионального
                                                        образования
                                                    </option>
                                                    <option
                                                        value="студент, осваивающий программы высшего образования">студент,
                                                        осваивающий программы высшего образования
                                                    </option>
                                                </optgroup>
                                                <optgroup
                                                    label="незанятое население, в том числе пенсионер и другие категории, не перечисленные в указанных выше полях">
                                                    <option
                                                        value="незанятое население, в том числе пенсионер и другие категории, не перечисленные в указанных выше полях">незанятое
                                                        население, в том числе пенсионер и другие категории, не
                                                        перечисленные в указанных выше полях
                                                    </option>
                                                </optgroup>
                                            </Field>
                                        </div>
                                        {errors.razdel2 && touched.razdel2 ? (
                                            <div className={cc.wrong}>{errors.razdel2}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Раздел 3 <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field as="select"
                                                   required={!(data && data.раздел3 !== "")}
                                                   style={isWrong(errors.razdel3, touched.razdel3)}
                                                   name="razdel3">
                                                <option disabled selected value="">Выберите подходящее</option>
                                                <optgroup label="лицо с ограниченными возможностями здоровья">
                                                    <option
                                                        value="лицо с ограниченными возможностями здоровья, относимых к категории инвалидов">относимых
                                                        к категории инвалидов
                                                    </option>
                                                    <option
                                                        value="лицо с ограниченными возможностями здоровья, не относимых к категории инвалидов">не
                                                        относимых
                                                        к категории инвалидов
                                                    </option>
                                                </optgroup>
                                                <optgroup label="инвалид, не относящийся к категории лиц с ОВЗ">
                                                    <option
                                                        value="инвалид, не относящийся к категории лиц с ОВЗ">инвалид,
                                                        не
                                                        относящийся к категории лиц с ОВЗ
                                                    </option>
                                                </optgroup>
                                                <optgroup
                                                    label="Нет">
                                                    <option
                                                        value="Нет">Нет
                                                    </option>
                                                </optgroup>
                                            </Field>
                                        </div>
                                        {errors.razdel3 && touched.razdel3 ? (
                                            <div className={cc.wrong}>{errors.razdel3}</div>
                                        ) : null}
                                    </label>
                                </div>
                            </div>
                        </CourseDescription>
                        <CourseDescription
                            title="Почтовый адрес для отправки оригинала документа о прохождении обучения">
                            <div className={cc.editInfo}>
                                <div className={cc.formStyle}>
                                    <label className={cc.item}>
                                        <div>
                                            Индекс <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.индекс !== "")}
                                                style={isWrong(errors.index, touched.index)}
                                                name="index"/>
                                        </div>
                                        {errors.index && touched.index ? (
                                            <div className={cc.wrong}>{errors.index}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Населенный пункт <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                required={!(data && data.нас_пункт !== "")}
                                                style={isWrong(errors.mail_city, touched.mail_city)}
                                                name="mail_city"/>
                                        </div>
                                        {errors.mail_city && touched.mail_city ? (
                                            <div className={cc.wrong}>{errors.mail_city}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Улица
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                style={isWrong(errors.street, touched.street)}
                                                name="street"/>
                                        </div>
                                        {errors.street && touched.street ? (
                                            <div className={cc.wrong}>{errors.street}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Номер дома
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                style={isWrong(errors.number, touched.number)}
                                                name="number"/>
                                        </div>
                                        {errors.number && touched.number ? (
                                            <div className={cc.wrong}>{errors.number}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Корпус
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                style={isWrong(errors.korpus, touched.korpus)}
                                                name="korpus"/>
                                        </div>
                                        {errors.korpus && touched.korpus ? (
                                            <div className={cc.wrong}>{errors.korpus}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Строение
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                style={isWrong(errors.building, touched.building)}
                                                name="building"/>
                                        </div>
                                        {errors.building && touched.building ? (
                                            <div className={cc.wrong}>{errors.building}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item}>
                                        <div>
                                            Квартира
                                        </div>
                                        <div className={cc.mid}>
                                            <Field
                                                style={isWrong(errors.apartment, touched.apartment)}
                                                name="apartment"/>
                                        </div>
                                        {errors.apartment && touched.apartment ? (
                                            <div className={cc.wrong}>{errors.apartment}</div>
                                        ) : null}
                                    </label>
                                </div>
                            </div>
                        </CourseDescription>
                        <CourseDescription title="Файлы (требуемые форматы: pdf, png, jpg, jpeg)">
                            <div className={cc.editInfo}>
                                <div className={cc.formStyle}>
                                    <div style={{width: "100%", color: "red", fontSize: "24px", marginBottom: "30px"}}>
                                        Внимание! В каждую секцию можно вставить не более одного файла!
                                    </div>
                                    <label className={cc.item} style={{paddingBottom: "10px"}}>
                                        <div>
                                            Заявка о приеме на обучение от учреждения (нужно только для бюджетных
                                            курсов)
                                        </div>
                                        <div style={{paddingTop: "8px"}}>
                                            <a target="_blank" href="https://dpo.fcprc.ru/media/media/behavior/zayavka_o_prieme_ot_uchrezhdeniya.docx">Бланк для заполнения</a>
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected1}
                                                name="file1"
                                                type="file"/>
                                        </div>
                                        {data.файл_оплаты && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.файл_оплаты}>Ранее прикрепленная
                                                заявка</a>
                                        </div>}
                                    </label>
                                    <label className={cc.item} style={{paddingBottom: "10px"}}>
                                        <div>
                                            Договор об оказании платных образовательных услуг (для платных курсов)
                                        </div>
                                        <div style={{paddingTop: "8px"}}>
                                            <a target="_blank" href="https://dpo.fcprc.ru/media/media/behavior/dogovor_fiz_lico.doc">Бланк для заполнения для физ.лица</a>
                                        </div>
                                        <div style={{paddingTop: "8px"}}>
                                            <a target="_blank" href="https://dpo.fcprc.ru/media/media/behavior/dogovor_ur_lico.doc">Бланк для заполнения для юр. лица</a>
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected5}
                                                name="file5"
                                                type="file"/>
                                            {file5 !== "" &&
                                                <input
                                                    style={{marginTop: "0", paddingTop: "0"}}
                                                    onChange={onMainPhotoSelectedDogovor}
                                                    name="dogovor2"
                                                    type="file"/>
                                            }
                                        </div>
                                        {data.договор_платных_услуг && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.договор_платных_услуг}>Ранее
                                                прикрепленный договор</a>
                                        </div>}
                                    </label>
                                    <label className={cc.item} style={{paddingBottom: "10px"}}>
                                        <div>
                                            Личное заявление физического лица <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div style={{paddingTop: "8px"}}>
                                            <a target="_blank" href={blank2}>Бланк для заполнения</a>
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected2}
                                                required={!(data && data.заявление !== "")}
                                                style={isWrong(errors.file2, touched.file2)}
                                                name="file2"
                                                type="file"/>
                                            {file2 !== "" && <input
                                                onChange={onMainPhotoSelectedZayavlenie}
                                                style={isWrong(errors.file2, touched.file2)}
                                                name="zayavlenie2"
                                                type="file"/>}
                                        </div>
                                        {data.заявление && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.заявление}>Ранее прикрепленное
                                                заявление</a>
                                        </div>}
                                        {errors.file2 && touched.file2 ? (
                                            <div className={cc.wrong}>{errors.file2}</div>
                                        ) : null}

                                        {data.заявление2 && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.заявление2}>Ранее прикрепленное
                                                заявление (2)</a>
                                        </div>}
                                    </label>
                                    <label className={cc.item} style={{paddingBottom: "10px"}}>
                                        <div>
                                            Согласие на обработку персональных данных <span
                                            style={{color: "red"}}>*</span>
                                        </div>
                                        <div style={{paddingTop: "8px"}}>
                                            <a target="_blank" href="https://dpo.fcprc.ru/media/media/behavior/soglasie_pd.docx">Бланк для заполнения</a>
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected3}

                                                required={!(data && data.файл_согласия !== "")}
                                                style={isWrong(errors.file3, touched.file3)}
                                                name="file3"
                                                type="file"/>
                                        </div>
                                        {data.Документ_фото && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.Документ_фото}>Ранее прикрепленное
                                                согласие</a>
                                        </div>}
                                        {errors.file3 && touched.file3 ? (
                                            <div className={cc.wrong}>{errors.file3}</div>
                                        ) : null}
                                    </label>

                                    <label className={cc.item} style={{paddingBottom: "10px"}}>
                                        <div>
                                            Согласие на обработку персональных данных, разрешенных субъектом
                                            персональных данных для
                                            распространения <span style={{color: "red"}}>*</span>
                                        </div>
                                        <div style={{paddingTop: "8px"}}>
                                            <a target="_blank" href="https://dpo.fcprc.ru/media/media/behavior/soglasie_pd_subject.docx">Бланк для заполнения</a>
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected4}
                                                required={!(data && data.файл_согласия_рег !== "")}
                                                style={isWrong(errors.file4, touched.file4)}
                                                name="file4"
                                                type="file"/>
                                        </div>
                                        {data.файл_согласия_рег && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.файл_согласия_рег}>Ранее
                                                прикрепленное
                                                согласие</a>
                                        </div>}
                                        {errors.file4 && touched.file4 ? (
                                            <div className={cc.wrong}>{errors.file4}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Квитанция об оплате
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected6}
                                                style={isWrong(errors.file6, touched.file6)}
                                                name="file6"
                                                type="file"/>
                                        </div>
                                        {data.квитанция && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.квитанция}>Ранее прикрепленная
                                                квитанция</a>
                                        </div>}
                                        {errors.file6 && touched.file6 ? (
                                            <div className={cc.wrong}>{errors.file6}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Фото СНИЛС <span
                                            style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected7}
                                                required={!(data && data.фото_снилс !== "")}
                                                style={isWrong(errors.file7, touched.file7)}
                                                name="file7"
                                                type="file"/>
                                        </div>
                                        {data.фото_снилс && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.фото_снилс}>Ранее прикрепленное
                                                СНИЛС</a>
                                        </div>}
                                        {errors.file7 && touched.file7 ? (
                                            <div className={cc.wrong}>{errors.file7}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Фото паспорта <span
                                            style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected8}
                                                required={!(data && data.фото_паспорт !== "")}
                                                style={isWrong(errors.file8, touched.file8)}
                                                name="file8"
                                                type="file"/>
                                            {
                                                file8 !== "" &&
                                                <input
                                                    onChange={onMainPhotoSelectedPass}
                                                    style={isWrong(errors.file8, touched.file8)}
                                                    name="pasport2"
                                                    type="file"/>
                                            }
                                        </div>
                                        {data.фото_паспорт && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.фото_паспорт}>Ранее прикрепленный
                                                паспорт</a>
                                        </div>}
                                        {data.фото_паспорт2 && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.фото_паспорт2}>Ранее прикрепленный
                                                паспорт (2)</a>
                                        </div>}
                                        {errors.file8 && touched.file8 ? (
                                            <div className={cc.wrong}>{errors.file8}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Фото документа об образовании <span
                                            style={{color: "red"}}>*</span>
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected9}
                                                required={!(data && data.Документ_фото !== "")}
                                                style={isWrong(errors.file9, touched.file9)}
                                                name="file9"
                                                type="file"/>
                                            {file9 !== "" && <input
                                                onChange={onMainPhotoSelectedDoc}
                                                name="doc2"
                                                type="file"/>}
                                        </div>
                                        {data.Документ_фото && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.Документ_фото}>Ранее прикрепленное
                                                фото</a>
                                        </div>}
                                        {data.Документ_фото2 && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.Документ_фото2}>Ранее прикрепленное
                                                фото (2)</a>
                                        </div>}
                                        {errors.file9 && touched.file9 ? (
                                            <div className={cc.wrong}>{errors.file9}</div>
                                        ) : null}
                                    </label>
                                    <label className={cc.item}>
                                        <div>
                                            Фото свидетельства о перемене имени / свидетельства о браке (если фамилия в
                                            дипломе не совпадает с фамилией в паспорте)
                                        </div>
                                        <div className={cc.mid}>
                                            <input
                                                onChange={onMainPhotoSelected10}
                                                style={isWrong(errors.file10, touched.file10)}
                                                name="file10"
                                                type="file"/>
                                            {file10 !== "" && <input
                                                onChange={onMainPhotoSelectedSmena}
                                                style={isWrong(errors.file10, touched.file10)}
                                                name="smena2"
                                                type="file"/>}
                                        </div>

                                        {data.Смена_фото && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.Смена_фото}>Ранее прикрепленное
                                                фото</a>
                                        </div>}
                                        {errors.file10 && touched.file10 ? (
                                            <div className={cc.wrong}>{errors.file10}</div>
                                        ) : null}
                                        {data.Смена_фото2 && <div>
                                            <a target="_blank" style={{color: "#cb2d5f"}} href={data.Смена_фото2}>Ранее прикрепленное
                                                фото (2)</a>
                                        </div>}
                                    </label>

                                </div>
                            </div>
                        </CourseDescription>
                        <label className={cc.final}>
                            <div className={cc.toggle}>
                                <Field required type="checkbox" name="toggle"/> Подтвердите правильность
                                введенных
                                данных
                            </div>
                            {errors.toggle && touched.toggle ? (
                                <div className={cc.wrong}>{errors.toggle}</div>
                            ) : null}
                        </label>
                        <div className={cc.final}>
                            <button disabled={!allow} style={!allow ? {cursor: "default"} : {}}
                                    type="submit">{send}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
export default CabinetEdit


function isWrong(errors, touched) {
    return errors && touched ? {border: "1px solid red"} : {border: "1px solid #CCCCCC"};
}
