export const questionFilterData = [
    {
        title: "Личный кабинет",
        url: "/",
    },
    {
        title: "Заказ и оформление товара",
        url: "/"
    },
    {
        title: "Оплата",
        url: "/"
    },
    {
        title: "Обучение",
        url: "/"
    },
    {
        title: "Оформление и выдача документов",
        url: "/"
    },
    {
        title: "Другие вопросы",
        url: "/"
    }

]