import m from "../../CoursesExamples/CourseEx.module.css"
import {CourseDescription, CourseFilter, PageTitle, SubTitle} from "../../Modules/Modules";
import {TeacherBlock} from "../../CoursesExamples/CourseExample1";
import s from "../../Modules/Modules.module.css";
import {MC2Array} from "../../CoursesExamples/MediationCourse2";
import file1 from "../../../assets/FilesForDeleteInFuture/Процедура проведения аттестации.pdf"
import file2 from "../../../assets/FilesForDeleteInFuture/Расписание занятий группы № ПК-СП-1.pdf"

const MC2Information = () => {
    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Медиация"/>
                    <PageTitle
                        title="Практикум начинающего медиатора: учимся на кейсах"/>
                    <TeacherBlock name="Марина Викторовна Быкова"
                                  position='Аналитик отдела медиации ФГБУ "Центр защиты прав и интересов детей"'/>
                    <p style={{fontSize: "30px"}}></p>
                    <CourseDescription title="Информация для ознакомления">
                        <CourseDesc/>
                    </CourseDescription>
                </div>
                <div className={m.courseRight}>
                    <CourseFilter filterArray={MC2Array}/>
                </div>
            </div>

        </div>
    )
}
export default MC2Information

const CourseDesc = () => {
    return <div className={s.courseDescription}>
        <div className={s.ref}>
            <a href="https://dpo.fcprc.ru/media/media/behavior/ДПП_повышения_квалификации_Практикум_начинающего_медиатора_учимся_на_кейсах.pdf">Дополнительная
                профессиональная программа повышения квалификации «Практикум начинающего медиатора: учимся на
                кейсах»</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={file2}>Расписание
                занятий группы № ПК-С/П-1</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={file1}>Процедура
                проведения аттестации</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href="https://t.me/+Jd3CBw88Xp80MjVi">Группа в телеграм</a>
        </div>
    </div>
}