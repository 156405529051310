import s from "./Main.module.css"
import {Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {useState} from "react";


const ForgetPassword = () => {

    const [errorM, setErrorM] = useState('')

    const onSubmit = (username, password) => {
        debugger
        let formdata = new FormData();
        formdata.append("email", username);
        formdata.append("password", password);
        let myHeaders = new Headers();
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
            withCredentials: true
        }
        const baseURL = 'https://dpo.fcprc.ru/api/'
        //login(requestOptions)
        fetch(baseURL + "auth/jwt/create/", requestOptions)
    }
    const [wrong, setWrong] = useState(false)
    let navigate = useNavigate()
    let formdata = new FormData();
    const infoSchema = Yup.object().shape({
        email: Yup.string()
            .required('Обязательное поле!')
    });
    return (
        <div className={s.main}>
            <Formik
                initialValues={{email: ""}}
                onSubmit={async (values) => {
                    formdata.append("email", values.email);
                    let myHeaders = new Headers();
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow',
                        withCredentials: true
                    }
                    fetch("https://dpo.fcprc.ru/api/auth/users/reset_password/", requestOptions)
                        .then(response =>
                            response.text()
                        )
                        .then(result => {

                            let resp = result ? JSON.parse(result) : ''
                            if (resp[0] === 'Пользователь с данным адресом электронной почты не существует.') {
                                setErrorM("Пользователь с данным адресом электронной почты не существует. Зарегистрируйтесь на портале или обратитесь в тех. поддержку")
                            } else if (resp && resp!== "") {
                                setErrorM(resp)
                            } else navigate("/reset-password-confirm")
                        })
                }}
                validationSchema={infoSchema}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <div className={s.loginForm}>
                            <h1>Восстановление доступа</h1>
                            <div className={s.loginInfo}>
                                <label>
                                    Почта пользователя
                                </label>
                                <Field type="email"
                                       name="email"/>
                                {errors.email && touched.email ? (
                                    <div className={s.wrong}>{errors.email}</div>
                                ) : null}
                                <button type="submit" >
                                    Отправить
                                </button>
                                {errorM !== '' ?
                                    <div style={{color: "red", textAlign: "center"}}>{errorM}</div> : <></>}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
export default ForgetPassword

