import s from "./Main.module.css"


const ResetPasswordConfirm = () => {

    return (
        <div className={s.main}>
            <div className={s.loginForm}>
                <h1>Восстановление доступа</h1>
                <div className={s.loginInfo} style={{textAlign: "center"}}>
                    <label>
                        Мы отправили письмо на указанную почту. Перейдите по ссылке в письме для сброса пароля.
                    </label>
                    <label>
                        <a href="/">Перейти на главную страницу</a>
                    </label>
                </div>
            </div>
        </div>
    )
}
export default ResetPasswordConfirm

