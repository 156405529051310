import s from "./Main.module.css"
import {PageTitle} from "../Modules/Modules";
const ErrorPage = () => {

    return (
        <div className={s.main}>
            <PageTitle title="Ошибка!"/>
            <div style={{paddingTop: "40px", fontSize: "18px"}}>
                Страница не найдена!
            </div>
        </div>
    )
}
export default ErrorPage

