import s from "./Main.module.css"
import {AskQuest, Filter, PageTitle, QuestionBlock} from "../Modules/Modules";
import {questionFilterData} from "../../Examples/questionData";
const Questions = ()=> {
    return (
        <div className={s.main}>
            <PageTitle title="Ответы на частые вопросы"/>
            <div className={s.filterPage}>
                <Filter filterArray={questionFilterData}/>
                <div className={s.filePart}>
                    <QuestionBlock title="Личные данные"/>
                    <QuestionBlock title="Личные данные"/>
                </div>

            </div>
            <AskQuest/>
        </div>
    )
}
export default Questions