import s from "./Footer.module.css"
import img1 from "../../assets/Footer/RFMP.png"
import img2 from "../../assets/Footer/svg-editor-image.svg"
import MobileFooter from "./MobileFooter";

const Footer = () => {
    return (
        <div>
            {window.innerWidth <= 976 ? <MobileFooter/> : <div className={s.footer}>
                <div className={s.info}>
                    <div className={s.tech}>
                        <span>Тех. поддержка:<br/></span>
                        <a href="mailto:support@fcprcgov.ru">support@fcprcgov.ru</a>
                    </div>
                    <div className={s.title}>
                        <div className={s.upper}>
                            <a href="https://edu.gov.ru/">МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</a>
                        </div>
                        <div className={s.bottom}>
                            © ФГБУ "Центр защиты прав и интересов детей", 2023
                        </div>
                    </div>
                    <div className={s.icons}>
                    <span className={s.item}>
                        <a href="/"><img src={img1} alt=""/></a>
                    </span>
                        <span className={s.item}>
                        <a href="https://fcprc.ru"><img alt="" style={{width: "35px"}} src={img2}/></a>
                    </span>
                    </div>
                </div>

            </div>}
        </div>
    )
}
export default Footer