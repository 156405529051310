import s from "./Main.module.css"
import {LibrarySearch, PageTitle} from "../Modules/Modules";
import {useEffect, useState} from "react";
import axios from "axios";

const Webinars = () => {

    const [respData, setData] = useState("")


    useEffect(() => {
        axios.get("https://dpo.fcprc.ru/api/courses").then(
            res => {
                setData(res)
            })
    }, [])

    let coursesArray = respData ? [].concat(respData.data) : []

    return (
        <div className={s.main}>
            <PageTitle title="Календарь"/>
            <div style={{paddingTop: "40px", fontSize: "18px"}}>
                Страница в разработке!
            </div>
            {/*<LibrarySearch/>*/}
            {/*<div className={s.websList}>*/}
            {/*    {coursesArray.map((el) =>*/}
            {/*        <div className={s.websItem}>*/}
            {/*            <a href={el.ссылка_на_страницу}>*/}
            {/*                <div className={s.title}>*/}
            {/*                    {el.текст_курса}*/}
            {/*                </div>*/}
            {/*                <div className={s.calendarInfo}>*/}
            {/*                    <div className={s.type}>*/}
            {/*                        {el.направленность_курса}*/}
            {/*                    </div>*/}
            {/*                    <div className={s.date}>*/}
            {/*                        <strong>Срок реализации:</strong><p></p>*/}
            {/*                        {el.дата_начала}*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </a>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}
        </div>
    )
}
export default Webinars

const webs = [
    {
        title: "Споры родителей, связанные с воспитанием детей. Особенности рассмотрения споров, связанных  с воспитанием детей, с участием иностранного элемента",
        url: "/",
        date: "19 декабря 2023",
        type: "Семинар"
    },
    {
        title: "Другой вебинар",
        url: "/",
        date: "18 декабря 2023",
        type: "Вебинар"
    },
    {
        title: "Другой вебинар",
        url: "/",
        date: "18 декабря 2023",
        type: "Вебинар"
    },
    {
        title: "Другой вебинар",
        url: "/",
        date: "18 декабря 2023",
        type: "Вебинар"
    },
    {
        title: "Другой вебинар",
        url: "/",
        date: "18 декабря 2023",
        type: "Вебинар"
    },
    {
        title: "Другой вебинар",
        url: "/",
        date: "18 декабря 2023",
        type: "Вебинар"
    },
    {
        title: "Другой вебинар",
        url: "/",
        date: "18 декабря 2023",
        type: "Вебинар"
    },
    {
        title: "Другой вебинар",
        url: "/",
        date: "18 декабря 2023",
        type: "Вебинар"
    },
    {
        title: "Другой вебинар",
        url: "/",
        date: "18 декабря 2023",
        type: "Вебинар"
    },
    {
        title: "Другой вебинар",
        url: "/",
        date: "18 декабря 2023",
        type: "Вебинар"
    }
]