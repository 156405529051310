import s from "../MainPages/Main.module.css"
import {Filter, PageTitle} from "../Modules/Modules";
import {useEffect, useState} from "react";
import axios from "axios";
import {filterArray, WebWindow} from "../MainPages/Courses";

const FilterCourses = ({otdel}) => {

    const [respData, setData] = useState("")


    useEffect(() => {
        axios.get("https://dpo.fcprc.ru/api/courses").then(
            res => {
                setData(res)
            })
    }, [])





    let coursesArray = respData ? [].concat(respData.data).filter(x =>
        x.отдел_курса.includes(otdel)): []


    return (
        <div className={s.main}>
            <PageTitle title={"Курсы ДПО - " + otdel}/>
            <div className={s.newFilterPage}>
                <Filter filterTitle="Все курсы" filterArray={filterArray}/>
                <div className={s.coursePart}>
                    {respData ? coursesArray.map(el =>
                        <WebWindow el={el}/>
                    ) : <></>}
                </div>
            </div>
        </div>
    )
}
export default FilterCourses




