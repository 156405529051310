import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css"
import {CourseDescription, PageTitle, SubTitle} from "../Modules/Modules";
import bc from "../../assets/Imgs/BigCourse.png";
import {TeacherBlock} from "./CourseExample1";
import opeca from "../../assets/Imgs/opeca.jpg";

const OpecaCourse1 = () => {

    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Опека и попечительство"/>
                    <PageTitle
                        title="Особенности подготовки и сопровождения опекунов или попечителей несовершеннолетних"/>
                    <TeacherBlock name="Бобылёва Ирина Анатольевна"
                                  position='Аналитик ФГБУ "Центр защиты прав и интересов детей", кандидат педагогических наук'/>
                    {window.innerWidth < 976 ?
                        <div className={m.courseRight}>
                            <p></p>
                            <BuyWindow/>
                        </div> : <></>
                    }
                    <div className={m.courseImg}>
                        <img src={opeca} alt=""/>
                    </div>
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                    {/*<CourseDescription title="Раздел 1.">*/}
                    {/*    <Razdel/>*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Раздел 2.">*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Календарь">*/}
                    {/*    <CalendarExample/>*/}
                    {/*</CourseDescription>*/}
                </div>
                {window.innerWidth >= 976 ?
                    <div className={m.courseRight}>
                    <BuyWindow/>
                    </div> : <></>
                }
            </div>
        </div>
    )
}
export default OpecaCourse1


const CourseDesc = () => {
    return <div className={s.courseDescription}>

        Дополнительная профессиональная программа повышения квалификации (далее - программа) направлена на рассмотрение:
        <ul>
            <li>
                организационно-правовых вопросов подготовки и сопровождения
            </li>
            <li>
                социально-педагогических, психолого-педагогических аспектов подготовки и сопровождения
            </li>
        </ul>
        Программа предназначена для повышения квалификации работников органов опеки и попечительства, специалистов школ
        приемных родителей,
        осуществляющих подготовку, служб сопровождения.
        <p></p>
        <strong>Цель программы:</strong><br style={{fontSize: "24px"}}/>
        Целью программы является получение знаний об особенностях подготовки лиц, выразивших желание принять в семью на
        воспитание детей-сирот и детей, оставшихся без попечения родителей (далее – кандидаты в опекуны),
        трудноустраиваемых категорий, сопровождения опекунов или попечителей.
        <p></p>
        <strong>Программа курса:</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                Особенности подготовки лиц, выразивших желание принять в семью на воспитание подростков, детей с
                ограниченными возможностями здоровья, включая инвалидность, с психическими расстройствами.
            </li>
            <li>
                Формы и методы повышения ресурсности и профилактики эмоционального выгорания опекунов или попечителей.
            </li>
            <li>
                Взаимодействие с органом опеки и попечительства по вопросам семейного устройства детей-сирот и детей,
                оставшихся без попечения родителей, и воспитания подопечного ребенка.
            </li>
            <li>
                Организация и ведение работы с кандидатами в опекуны, опекунами или попечителями с учетом региональных,
                конфессиональных, национальных и иных факторов.
            </li>
            <li>
                Инструменты для психологического обследование кандидатов в опекуны.
            </li>
            <li>
                Обзор практики по организации и осуществлению подготовки кандидатов в опекуны или попечители и
                сопровождению семей опекунов (попечителей).
            </li>
            <li>
                Итоговая аттестация
            </li>
        </ul>
        <p></p>
        <strong>Преподаватели (планируемые):</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                <b>Марина Валерьевна Лашкул</b>, заместитель директора по вопросам опеки и попечительства;
            </li>
            <li>
                <b>Яровикова Ольга Анатольевна</b>, руководитель Федерального ресурсного центра в сфере защиты прав
                детей-сирот и детей, оставшихся без попечения родителей;
            </li>
            <li>
                <b>Зверева Наталья Владимировна</b>, аналитик I категории;
            </li>
            <li>
                <b>Бобылёва Ирина Анатольевна</b>, аналитик.
            </li>
        </ul>
        <p></p>
        <strong>Характеристики курса</strong><br style={{fontSize: "24px"}}/>
        Трудоемкость программы: 36 ак. часов <br/>
        Длительность (недель): 2<br/>
        Учебная нагрузка по курсу: до 4 часов в день.<br/>
        Язык курса: русский<br/>
        Удостоверение установленного образца: да<br/>
        Календарный план: май, декабрь
        <p></p>
        <strong>Период обучения: с 19 августа по 4 сентября 2024 года</strong><br style={{fontSize: "24px"}}/>
        Расписание занятий:<br/>
        10.00 – 11.30 – 1 пара<br/>
        11.30 – 11.40 – перерыв<br/>
        11.40 – 13.10 – 2 пара
    </div>
}

// const Razdel = () => {
//     return <div className={s.courseDescription}>
//         <strong>Правовые основы и направления деятельности органов опеки и попечительства при рассмотрении споров о
//             воспитании
//             детей</strong>
//         <p></p>
//         <div className={s.ref}>
//             <a href="/course1/part1"><strong>Подраздел 1.1</strong><br/>
//                 Правовые основания и направления деятельности органов опеки и попечительства при рассмотрении споров о
//                 воспитании детей (на досудебной стадии, в судебном процессе, в исполнительном производстве)</a>
//         </div>
//         <p></p>
//         <strong>Подраздел 1.2</strong><br/>
//         Учет мнения ребенка при решении вопросов, затрагивающих его права и интересы. Методы и способы выяснения мнения
//         ребенка в случаях, предусмотренных законодательством. Учет психологических, медицинских, социальных факторов при
//         общении с детьми разных возрастных групп при выявлении их мнения
//     </div>
// }
//
// const Zadanie = ({
//                      questions
//                  }) => {
//     return (
//         <div className={s.courseDescription}>
//             {questions.map(el =>
//                 <Question id={el.id} question={el.question} answer={el.answer}/>
//             )}
//             <div className={s.send}>
//                 <a href="/">Отправить ответы</a>
//             </div>
//
//         </div>
//     )
// }
//
// const Question = ({
//                       id, question, answer = null
//                   }) => {
//     return (
//         <div>
//             <div style={{paddingBottom: "20px"}}>
//                 <strong>Вопрос {id}</strong>
//             </div>
//             <div style={{paddingBottom: "20px"}}>
//                 {question}
//             </div>
//             <div className={s.qInput}>
//                 <input style={answer === null ? {backgroundColor: "white"} : {backgroundColor: "#008E4C17"}}
//                        id="questionInput" type="text" placeholder={answer === null ? "Ответ" : answer}/>
//                 <button id="searchButton"
//                         style={answer === null ? {backgroundColor: "#e07b9b"} : {backgroundColor: "#3F5070"}}>{answer === null ? "Ответить" : "Ответ принят"}</button>
//             </div>
//
//         </div>
//     )
// }

const BuyWindow = () => {
    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                {/*<div style={{fontSize: "16px", paddingBottom: "10px"}}>*/}
                {/*    <span><strong>0 человек</strong></span> прошли курс*/}
                {/*</div>*/}
                <div style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}>
                    36 академических часов
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px"
                }}>
                    <div style={{fontSize: "12px", color: "#808080"}}>
                        Стоимость курса
                    </div>
                    <div style={{fontSize: "18px"}}>
                        <strong>15 000 ₽</strong>
                    </div>
                </div>
                <div className={m.buyButton}>
                    <a href="/registration">Записаться на курс</a>
                </div>
            </div>
        </div>
    )
}

