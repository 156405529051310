import s from "./Main.module.css"
import "../Modules/Carousel.module.css"
import React, {useEffect, useState} from "react";
import {CourseSlide, FreeCourseSlide, NewCourseCarousel, NewsCard} from "../Modules/CourseCarousel";
import {courseExample} from "./Courses";
import banner1 from "../../assets/Main/banner1.png"
import banner2_1 from "../../assets/Main/banner2_1.png"
import banner2_2 from "../../assets/Main/banner2_2.png"
import banner2_3 from "../../assets/Main/banner2_3.png"
import inst1 from "../../assets/Main/Inst1.png"
import inst2 from "../../assets/Main/Inst2.png"
import inst3 from "../../assets/Main/Inst3.png"
import udost1 from "../../assets/Main/udost1.jpg"
import udost2 from "../../assets/Main/udost2.jpg"
import axios from "axios";
import {GrayButton} from "../Modules/Buttons";


const Main = () => {

    const [respData, setData] = useState("")


    useEffect(() => {
        axios.get("https://dpo.fcprc.ru/api/courses").then(
            res => {
                setData(res)
            })
    }, [])

    let coursesArray = respData ? [].concat(respData.data) : []

    return (
        <div>
            <div className={s.banner1}>
                <div className={s.infoPart}>
                    <div className={s.left}>
                        <div className={s.upper}>
                            Учимся непрерывно
                        </div>
                        <div className={s.bottom}>
                            Дополнительное
                            профессиональное образование
                            ФГБУ «Центр защиты прав и интересов детей»
                        </div>
                    </div>
                    <div className={s.pic}>
                        <img src={banner1} alt=""/>
                    </div>
                </div>
            </div>
            <div className={s.banner2}>
                <div className={s.infoPart}>
                    <img src={banner2_1} alt=""/>
                    <img src={banner2_2} alt=""/>
                    <img src={banner2_3} alt=""/>
                </div>

            </div>
            {/*<NewsMain>*/}
            {/*    {courseExample.slice(0, 4).map((el) =>*/}
            {/*        <NewsCard el={el}/>*/}
            {/*    )}*/}
            {/*</NewsMain>*/}
            <div className={s.main}>
                <div style={{paddingBottom: "50px"}}>
                    <CoursesMain>
                        {coursesArray.map(el =>
                            <CourseSlide el={el}/>
                        )}
                    </CoursesMain>
                </div>
                <div>
                    <BigMainTitle title="Как приступить к учебе?"/>
                    <HowToStudy/>
                </div>
                <p></p>
                <div>
                    <BigMainTitle title="Удостоверение, выдаваемое прошедшим курсы повышения квалификации"/>
                    <p></p>
                    <div className={s.mainDiploms}>
                        <img src={udost1} alt=""/>
                        <img src={udost2} alt=""/>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Main;


export const BigMainTitle = ({title}) => {
    return (
        <div className={s.bigMainTitle}>
            {title}
        </div>
    )
}


const CoursesMain = (props) => {

    const {children} = props

    return (
        <div>
            <div className={s.recommend}>
                <div className={s.left}>
                    <div className={s.upper}>
                        <BigMainTitle title="Рекомендуемые курсы"/>
                    </div>
                    <div className={s.bottom}>
                        <span style={{color: "#CB2D5F"}}>Курсы повышения квалификации</span>
                    </div>
                </div>
                <div className={s.right}>
                    <GrayButton title="Смотреть все" url="/courses"/>
                </div>
            </div>
            <div className={s.coursesMain}>
                {children}
            </div>
        </div>
    )
}

const HowToStudy = () => {
    return (
        <div className={s.instruction}>
            <div className={s.instructionItem}>
                <div className={s.instImg}>
                    <img src={inst1} alt=""/>
                </div>
                <div>
                    Выберите программу из перечня образовательных курсов
                </div>
            </div>
            <div className={s.instructionItem}>
                <div className={s.instImg}>
                    <img src={inst2} alt=""/>
                </div>
                <div>
                    Оставьте заявку
                </div>
            </div>
            <div className={s.instructionItem}>
                <div className={s.instImg}>
                    <img src={inst3} alt=""/>
                </div>

                <div>
                    Дождитесь<br/>обратной связи
                </div>
            </div>
        </div>
    )
}

const NewsMain = (props) => {

    const {children} = props

    return (
        <div style={{backgroundColor: "white"}}>
            <div className={s.newsRecommend}>
                <div className={s.left}>
                    <div className={s.upper}>
                        <BigMainTitle title="Новости"/>
                    </div>
                </div>
                <div className={s.right}>
                    <GrayButton title="Смотреть все" url="/news"/>
                </div>
            </div>
            <div className={s.newsMain}>
                {children}
            </div>
        </div>
    )
}