import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css"
import {CourseDescription, CourseFilter, PageTitle, SubTitle} from "../Modules/Modules";
import bc from "../../assets/Imgs/BigCourse.png";
import {TeacherBlock} from "./CourseExample1";
import opeca from "../../assets/Imgs/opeca.jpg"
import {MC2Array} from "./MediationCourse2";
import {findCommonElement} from "../../App";

const OpecaCourse1 = () => {
    const perm=[110, 109]
    const gr=[6]
    let localPerm = localStorage.getItem("user_permissions") && localStorage.getItem("user_permissions") !== "" ?JSON.parse(localStorage.getItem("user_permissions")) : [];
    let localGroup = localStorage.getItem("groups") && localStorage.getItem("groups") !== "" ? JSON.parse(localStorage.getItem("groups")) : [];
    const found = localPerm !== [] ? findCommonElement(localPerm, perm) : false
    const found2 = localGroup !==[]? findCommonElement(gr, localGroup) : false
    const final = found || found2



    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Опека и попечительство"/>
                    <PageTitle
                        title="Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента"/>
                    <TeacherBlock  name="Марина Валерьевна Лашкул" position='Заместитель директора по вопросам опеки и попечительства ФГБУ "Центр защиты прав и интересов детей"'/>
                    {(window.innerWidth < 976 && (final === true || localStorage.getItem('course') === 'Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента'|| localStorage.getItem("isAdmin") === "true" || localStorage.getItem("isStaff") === "true")) ?
                        <div className={m.courseRight}>
                            <p></p>
                            <CourseFilter filterArray={OC1Array}/>
                        </div> : <></>
                    }
                    <div className={m.courseImg}>
                        <img src={opeca} alt=""/>
                    </div>
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                    {/*<CourseDescription title="Раздел 1.">*/}
                    {/*    <Razdel/>*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Раздел 2.">*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Календарь">*/}
                    {/*    <CalendarExample/>*/}
                    {/*</CourseDescription>*/}
                </div>
                <div className={m.courseRight}>
                    {
                        (window.innerWidth >= 976 && (final === true || localStorage.getItem('course') === 'Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента' || localStorage.getItem("isAdmin") === "true" || localStorage.getItem("isStaff") === "true"))?
                            <CourseFilter filterArray={OC1Array}/>
                            : <></>
                    }
                </div>
            </div>
        </div>
    )
}
export default OpecaCourse1


const CourseDesc = () => {
    return <div className={s.courseDescription}>

        Дополнительная профессиональная программа повышения квалификации (далее - программа) направлена на рассмотрение
        споров:
        <ul>
            <li>
                об ограничении и лишении родительских прав, об отмене ограничения и восстановлении родительских прав
            </li>
            <li>
                о месте жительства ребенка и порядке общения с ребенком отдельно проживающего родителя
            </li>
            <li>
                о незаконном похищении/удержании ребенка одним из родителей
            </li>
        </ul>
        Программа предназначена для повышения квалификации работников органов опеки и попечительства,
        осуществляющих
        содействие обеспечению и защите прав и законных интересов детей.
        <p></p>
        <strong>Цель программы:</strong><br style={{fontSize: "24px"}}/>
        Целью программы является получение правовых знаний об особенностях деятельности органов опеки и
        попечительства
        при рассмотрении споров о воспитании детей, в том числе с участием иностранного элемента.
        <p></p>
        <strong>Программа курса:</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                Правовые основания и направления деятельности органов опеки и попечительства при рассмотрении
                споров о
                воспитании детей (на досудебной стадии, в судебном процессе, в исполнительном производстве).
            </li>
            <li>
                Учет мнения ребенка при решении вопросов, затрагивающих его права и интересы. Методы и способы
                выяснения
                мнения ребенка в случаях, предусмотренных законодательством.
            </li>
            <li>
                Законодательство, применимое при рассмотрении споров, связанных с воспитанием детей с участием
                иностранного
                элемента
            </li>
            <li>
                Семейная медиация. Медиация в трансграничных семейных спорах
            </li>
            <li>
                Итоговая аттестация
            </li>
        </ul>
        <p></p>
        <strong>Преподаватели (планируемые):</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                <b>Галина Владимировна Семья</b>, доктор психологических наук, профессор;
            </li>
            <li>
                <b>Марина Валерьевна Лашкул</b>, заместитель директора по вопросам опеки и попечительства;
            </li>
            <li>
                <b>Анна Евгеньевна Тарасова</b>, кандидат юридических наук, юрист;
            </li>
            <li>
                <b>Бобылёва Ирина Анатольевна</b>, аналитик;
            </li>
            <li>
                <b>Сергей Леонидович Тюльканов</b>, аналитик.
            </li>
        </ul>
        <p></p>
        <strong>Характеристики курса</strong><br style={{fontSize: "24px"}}/>
        Трудоемкость программы: 36 ак. часов <br/>
        Длительность (недель): 2<br/>
        Учебная нагрузка по курсу: до 4 часов в день.<br/>
        Язык курса: русский<br/>
        Удостоверение установленного образца: да<br/>
        Календарный план: апрель, октябрь
        <p></p>
        <strong>Планируемый период обучения: с 15 апреля по 30 апреля 2024 года</strong><br style={{fontSize: "24px"}}/>
        Расписание занятий:<br/>
        10.00 – 11.30 – 1 пара<br/>
        11.30 – 11.40 – перерыв<br/>
        11.40 – 13.10 – 2 пара
    </div>
}

// const Razdel = () => {
//     return <div className={s.courseDescription}>
//         <strong>Правовые основы и направления деятельности органов опеки и попечительства при рассмотрении споров о
//             воспитании
//             детей</strong>
//         <p></p>
//         <div className={s.ref}>
//             <a href="/course1/part1"><strong>Подраздел 1.1</strong><br/>
//                 Правовые основания и направления деятельности органов опеки и попечительства при рассмотрении споров о
//                 воспитании детей (на досудебной стадии, в судебном процессе, в исполнительном производстве)</a>
//         </div>
//         <p></p>
//         <strong>Подраздел 1.2</strong><br/>
//         Учет мнения ребенка при решении вопросов, затрагивающих его права и интересы. Методы и способы выяснения мнения
//         ребенка в случаях, предусмотренных законодательством. Учет психологических, медицинских, социальных факторов при
//         общении с детьми разных возрастных групп при выявлении их мнения
//     </div>
// }
//
// const Zadanie = ({
//                      questions
//                  }) => {
//     return (
//         <div className={s.courseDescription}>
//             {questions.map(el =>
//                 <Question id={el.id} question={el.question} answer={el.answer}/>
//             )}
//             <div className={s.send}>
//                 <a href="/">Отправить ответы</a>
//             </div>
//
//         </div>
//     )
// }
//
// const Question = ({
//                       id, question, answer = null
//                   }) => {
//     return (
//         <div>
//             <div style={{paddingBottom: "20px"}}>
//                 <strong>Вопрос {id}</strong>
//             </div>
//             <div style={{paddingBottom: "20px"}}>
//                 {question}
//             </div>
//             <div className={s.qInput}>
//                 <input style={answer === null ? {backgroundColor: "white"} : {backgroundColor: "#008E4C17"}}
//                        id="questionInput" type="text" placeholder={answer === null ? "Ответ" : answer}/>
//                 <button id="searchButton"
//                         style={answer === null ? {backgroundColor: "#e07b9b"} : {backgroundColor: "#3F5070"}}>{answer === null ? "Ответить" : "Ответ принят"}</button>
//             </div>
//
//         </div>
//     )
// }

const BuyWindow = () => {
    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                {/*<div style={{fontSize: "16px", paddingBottom: "10px"}}>*/}
                {/*    <span><strong>0 человек</strong></span> прошли курс*/}
                {/*</div>*/}
                <div style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}>
                    36 академических часов
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px"
                }}>
                    <div style={{fontSize: "12px", color: "#808080"}}>
                        Стоимость курса
                    </div>
                    <div style={{fontSize: "18px"}}>
                        <strong>15 000 ₽</strong>
                    </div>
                </div>
                <div className={m.buyButton}>
                    <a href="/registration">Записаться на курс</a>
                </div>
            </div>
        </div>
    )
}
export const OC1Array = [
    {
        title: "Описание курса",
        url: "/opecacourse1",
    },
    {
        title: "Информация для обучающихся",
        url: "/opecacourse1/info"
    },
]
