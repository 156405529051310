import s from "./Main.module.css"


const AccRegistrationConfirm = () => {

    return (
        <div className={s.main}>
            <div className={s.loginForm}>
                <h1>Регистрация</h1>
                <div className={s.loginInfo} style={{textAlign: "center"}}>
                    <label>
                        Регистрация прошла успешно! На вашу почту была отправлена ссылка для активации аккаунта.
                        <p>
                            <span style={{color: "red"}}>Внимание! Без активации вы не сможете использовать зарегистрированный аккаунт!</span>
                        </p>
                    </label>
                    <label>
                        <a href="/">Перейти на главную</a>
                    </label>
                </div>
            </div>
        </div>
    )
}
export default AccRegistrationConfirm

