import s from "../MainPages/Main.module.css"
import m from "../Modules/Modules.module.css"
import {Filter, PageTitle, SubTitle} from "../Modules/Modules";
import {DocRef} from "../Modules/Buttons";
import {libratyData} from "../../Examples/libratyData";
import {useEffect, useState} from "react";
import axios from "axios";
import {SearchOutlined} from "@ant-design/icons";
import mr1 from "../../assets/FilesForDeleteInFuture/Library/mr170724.pdf"

const Library5 = ({title, num1, num2}) => {

    const [respData, setData] = useState('')


    const loadInfo = () => {

        axios.get("https://dpo.fcprc.ru/api/NDLetter")
            .then((res) => {
                setData(res)

            })
            .catch((e) => {

            });

    }
    useEffect(() => {
        loadInfo()
    }, [])



    let dop = [
        {
            description: "Методические рекомендации для органов опеки и попечительства",
            file: "/static/media/mr170724.535fe5c80477b8872979.pdf"
        }
    ]
    let response = respData.data ? respData.data.slice(num1, num2) : [];
    let newResponse = [...dop, ...response]


    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const results = newResponse.filter(p =>
            p.description.toLowerCase().includes(searchTerm)
        );
        setSearchResults(results);
    }, [searchTerm]);


    return (
        <div className={s.main}>
            <PageTitle title={title}/>
            <SubTitle title="Учебно-методическая литература для внеаудиторной работы слушателей"/>
            <div className={m.search}>
                <input id="searchInput" value={searchTerm} onChange={handleChange} type="text" placeholder="Поиск..."/>
                <button id="searchButton"><SearchOutlined/></button>
            </div>
            <div className={s.filterPage}>
                <Filter filterTitle="Все книги" filterArray={libratyData}/>
                <div className={s.filePart}>
                    {
                        searchTerm ? searchResults.map((el) =>
                            <DocRef title={el.description}
                                    url={el.file}/>
                        ) : newResponse.map((el) =>
                            <DocRef title={el.description}
                                    url={el.file}/>
                        )
                    }

                </div>
            </div>
        </div>
    )
}
export default Library5