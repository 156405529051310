import s from "./Main.module.css"
import {Field, Form, Formik} from "formik";
import {NavLink, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {useState} from "react";


const Login = ({setLogged}) => {

    const onSubmit = (username, password) => {
        debugger
        let formdata = new FormData();
        formdata.append("email", username);
        formdata.append("password", password);
        let myHeaders = new Headers();
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
            withCredentials: true
        }
        const baseURL = 'https://dpo.fcprc.ru/api/'
        //login(requestOptions)
        fetch(baseURL + "auth/jwt/create/", requestOptions)
    }
    const [wrong, setWrong] = useState(false)
    let navigate = useNavigate()
    let formdata = new FormData();
    const infoSchema = Yup.object().shape({
        username: Yup.string()
            .required('Обязательное поле!'),
        password: Yup.string()
            .required('Обязательное поле!')
    });
    return (
        <div className={s.main}>
            <Formik
                initialValues={{username: "", password: ""}}
                onSubmit={async (values) => {
                    formdata.append("username", values.username);
                    formdata.append("password", values.password);
                    let myHeaders = new Headers();
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow',
                        withCredentials: true
                    }
                    fetch("https://dpo.fcprc.ru/api/auth/jwt/create/", requestOptions)
                        .then(response =>
                            response.text()
                        )
                        .then(result => {

                            let accessToken = JSON.parse(result)
                            if (accessToken.access) {
                                localStorage.setItem("access", accessToken.access)
                                setLogged("Личный кабинет")
                                navigate("/cabinet")
                            } else if (accessToken.detail) {
                                setWrong(true)
                            }

                        })

                }}
                validationSchema={infoSchema}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <div className={s.loginForm}>
                            <h1>Вход</h1>
                            <div className={s.loginInfo}>
                                <label>
                                    Логин пользователя
                                </label>
                                <Field type="username"
                                       name="username"/>
                                {errors.username && touched.username ? (
                                    <div className={s.wrong}>{errors.username}</div>
                                ) : null}
                                <label>
                                    Пароль
                                </label>
                                <Field type="text"
                                       name="password"/>
                                <button type="submit">
                                    Отправить
                                </button>
                                {errors.password && touched.password ? (
                                    <div className={s.wrong}>{errors.password}</div>
                                ) : null}
                                {wrong ?
                                    <div style={{color: "red", textAlign: "center"}}>Неправильные имя пользователя или
                                        пароль!</div> : <></>}
                                <div className={s.registration} style={{paddingBottom: "10px"}}>
                                    <p>
                                        Забыли пароль?
                                    </p>
                                    <NavLink to="/reset-password">
                                        Восстановить пароль
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}
export default Login

