import s from "./Main.module.css"
import {Field, Form, Formik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import {useState} from "react";


const NewPassword = () => {

    const [errorM, setErrorM] = useState('')
    const {uid, token} = useParams();

    let navigate = useNavigate()


    const infoSchema = Yup.object().shape({
        password: Yup.string()
            .required('Обязательное поле!'),
        password2: Yup.string()
            .required('Обязательное поле!')
            .oneOf([Yup.ref('password')], 'Пароли должны совпадать!'),
    });
    return (
        <div className={s.main}>
            <Formik
                initialValues={{new_password: "", re_new_password: ""}}
                onSubmit={async (values) => {
                    let formData = new FormData();
                    formData.append("uid", uid);
                    formData.append("token", token);
                    formData.append("new_password", values.password);
                    formData.append("re_new_password", values.password2);
                    let myHeaders = new Headers();
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formData,
                        redirect: 'follow',
                        withCredentials: true
                    }
                    const baseURL = 'https://dpo.fcprc.ru/api/'
                    //login(requestOptions)
                    fetch(baseURL + "auth/users/reset_password_confirm/", requestOptions)
                        .then(response =>
                            response.text()
                        )
                        .then((result) => {
                                let resp = result ? JSON.parse(result) : ''

                                if (resp && resp !== '') {
                                    if (resp.new_password && resp.new_password.includes("Пользователь с таким логином уже существует.")) {
                                        setErrorM("Введённый пароль слишком широко распространён.!")
                                    } else if (resp.new_password && resp.new_password.includes("Введённый пароль слишком похож на имя пользователя.")) {
                                        setErrorM("Введённый пароль слишком похож на имя пользователя!")
                                    } else if (resp.new_password && resp.new_password.includes("Введённый пароль слишком похож на адрес электронной почты.")) {
                                        setErrorM("Пароль слишком похож на почту пользователя!")
                                    } else if (resp.new_password && resp.new_password.includes("Пароль слишком простой.")) {
                                        setErrorM("Пароль слишком простой!")
                                    } else if (resp.new_password && resp.new_password.includes("Введённый пароль состоит только из цифр.")) {
                                        setErrorM("Пароль не должен состоять только из чисел!")
                                    } else if (resp.new_password && resp.new_password.includes("Введённый пароль слишком широко распространён.")) {
                                        setErrorM("Введённый пароль слишком широко распространён!")
                                    } else {
                                        setErrorM("Что-то пошло не так! Попробуйте поменять введенные данные или повторите попытку позже")
                                    }
                                } else {
                                    navigate("/new-password-confirm")
                                }
                            }
                        )
                }}
                validationSchema={infoSchema}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <div className={s.loginForm}>
                            <h1>Восстановление доступа</h1>
                            <div className={s.loginInfo}>
                                <label>
                                    Новый пароль
                                </label>
                                <Field type="password"
                                       name="password"/>
                                {errors.password && touched.password ? (
                                    <div className={s.wrong}>{errors.password}</div>
                                ) : null}
                                <label>
                                    Повторите пароль
                                </label>
                                <Field type="password"
                                       name="password2"
                                />
                                {errors.password2 && touched.password2 ? (
                                    <div className={s.wrong}>{errors.password2}</div>
                                ) : null}
                                <button type="submit">
                                    Отправить
                                </button>
                                {errorM !== '' ?
                                    <div style={{color: "red", textAlign: "center"}}>{errorM}</div> : <></>}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
export default NewPassword

