import s from "./Feedback.module.css"
import m from "./Main.module.css"
import {PageTitle} from "../Modules/Modules";

const Feedback = () => {
    return (
        <div className={m.main}>
            <PageTitle title="Обратная связь"/>
            <form>
                <div className={s.support}>
                    {/*<h2>Обратная связь</h2>*/}
                    <div className={s.contacts}>

                        <label>
                            <input type="text" name="name" placeholder="Имя"/>
                        </label>
                        <label>
                            <input type="text" name="phone" placeholder="Фамилия"/>
                        </label>
                        <label>
                            <input type="text" name="phone" placeholder="Телефон"/>
                        </label>
                        <label>
                            <input type="text" name="mail" placeholder="E-mail"/>
                        </label>

                    </div>
                    <div className={s.question}>

                        <label>
                        <textarea placeholder="Вопрос"
                                  name="problem"></textarea>
                        </label>
                    </div>
                    <div className={s.supBtn}>
                        <input type="submit" placeholder="Отправить"/>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Feedback;