import s from "./Main.module.css"
import {PageTitle, SubTitle} from "../Modules/Modules";
import teacher1 from "../../assets/Imgs/volzhina.jpg"
import teacher2 from "../../assets/Imgs/kozlov.jpg"
import teacher3 from "../../assets/Imgs/bikova.png"
import teacher4 from "../../assets/Imgs/lashkul.jpg"

const Teachers = () => {
    return (
        <div className={s.main}>
            <PageTitle title="Наши преподаватели"/>
            <SubTitle title="Мастера своего дела с профильным образованием"/>
            <div className={s.teacherList}>
                <TeacherBlock img={teacher1} name="Волжина Оксана Борисовна"
                              position="Начальник отдела дополнительного профессионального образования ФГБУ «Центр защиты прав и интересов детей»"
                              exp="Кандидат педагогических наук, координатор и модератор групповой работы и проектной деятельности. Опыт работы в социальной сфере 10 лет, с 1998 года опыт преподавательской деятельности"
                              email="volzhina.o@fcprcgov.ru"/>
                <TeacherBlock img={teacher2} name="Козлов Анатолий Андреевич" position="Начальник отдела медиации"
                              exp="Тренер-преподаватель, имеет высшее психолого-педагогическое образование, медиатор, психолог, член Ассоциации профессиональных медиаторов «Единство», лектор Российского общества «Знание». Основной опыт в сфере развития коммуникативной и конфликтной компетентности детей, подростков и молодежи."
                              email="a.kozlov@fcprcgov.ru"/>
                <TeacherBlock img={teacher3} name="Быкова Марина Викторовна" position="Аналитик отдела медиации"
                              exp="Тренер-преподаватель, имеет высшее психолого-педагогическое образование, профессиональный тренер-медиатор, сертифицированный семейный медиатор Коллегии медиаторов Великобритании, многолетний опыт преподавания медиации как метода и тренерского курса в Центре медиации и права, МГППУ, МГЮА, МГУ."
                              email="bykova.marina@fcprc.ru"/>
                <TeacherBlock img={teacher4} name="Лашкул Марина Валерьевна"
                              position='Заместитель директора ФГБУ "Центр защиты прав и интересов детей" по вопросам опеки и попечительства в отношении несовершеннолетних граждан (Минпросвещения России)'
                              exp='Преподаватель кафедры возрастной психологии имени профессора Л.Ф. Обуховой факультета "Психология образования"'
                              email="lashkul.m@fcprcgov.ru"/>

            </div>
        </div>
    )
}
export default Teachers

const TeacherBlock = ({img, name, position, exp, email}) => {
    return (
        <div className={s.teacherBlock}>
            <img src={img} alt=""/>
            <div className={s.info}>
                <div className={s.name}>
                    {name}
                </div>
                <div className={s.position}>
                    {position}
                </div>
                <div className={s.dopInfo}>
                    <strong>Опыт работы:</strong> {exp}
                </div>
                <div className={s.dopInfo}>
                    <strong>E-mail:</strong> {email}
                </div>
            </div>

        </div>
    )
}