export const libratyData = [
    {
        title: "Законодательства и иные нормативные акты",
        url: "/library_cat/zakonodatelstva-i-inye-normativnye-akty",
    },
    {
        title: "Международные документы",
        url: "/library_cat/mezhdunarodnye-dokumenty"
    },
    {
        title: "Обзоры практики Верховного Суда Российской Федерации",
        url: "/library_cat/obzory-praktiki-verhovnogo-suda-rossijskoj-federaczii"
    },
    {
        title: "Рекомендуемая литература",
        url: "/library_cat/rekomenduemaya-literatura"
    },
    {
        title: "Наши методические разработки",
        url: "/library_cat/nashi-metodicheskie-razrabotchiki"
    }

]