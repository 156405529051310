import s from "./Main.module.css"
import {Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {useState} from "react";


const AccRegistration = () => {
    const [errorM, setErrorM] = useState('')
    let navigate = useNavigate()
    let formdata = new FormData();
    const infoSchema = Yup.object().shape({
        username: Yup.string()
            .required('Обязательное поле!'),
        email: Yup.string()
            .required('Обязательное поле!')
            .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/, "Неправильный формат почты"),
        password: Yup.string()
            .required('Обязательное поле!')
            .min(8, "Пароль должен содержать минимум 8 символов")
    });
    return (
        <div className={s.main}>
            <Formik
                initialValues={{username: "", email: "", password: ""}}
                onSubmit={async (values) => {
                    formdata.append("username", values.username);
                    formdata.append("email", values.email);
                    formdata.append("password", values.password);
                    let myHeaders = new Headers();
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow',
                        withCredentials: true
                    }
                    fetch("https://dpo.fcprc.ru/api/auth/users/", requestOptions)
                        .then(response =>
                            response.text()
                        )
                        .then(result => {

                            let resp = JSON.parse(result)
                            if (resp.username && resp.username.includes("Пользователь с таким логином уже существует.")) {
                                setErrorM("Пользователь с таким логином уже существует!")
                            } else if (resp.username && resp.username.includes("Введите правильное имя пользователя. Оно может содержать только буквы, цифры и знаки @/./+/-/_.")) {
                                setErrorM("Имя пользователя может состоять только из букв, цифр и спец. символов @.+-_. Пробелы запрещены")
                            } else if (resp.password && resp.password.includes("Введённый пароль слишком похож на имя пользователя.")) {
                                setErrorM("Введённый пароль слишком похож на имя пользователя!")
                            } else if (resp.password && resp.password.includes("Введённый пароль слишком похож на адрес электронной почты.")) {
                                setErrorM("Пароль слишком похож на почту пользователя!")
                            } else if (resp.password && resp.password.includes("Пароль слишком простой.")) {
                                setErrorM("Пароль слишком простой!")
                            } else if (resp.password && resp.password.includes("Введённый пароль состоит только из цифр.")) {
                                setErrorM("Пароль не должен состоять только из чисел!")
                            } else if (resp.password && resp.password.includes("Введённый пароль слишком широко распространён.")) {
                                setErrorM("Введённый пароль слишком широко распространён!")
                            } else if (resp.id && resp.username && resp.email) {
                                navigate("/confirm")
                            } else {
                                setErrorM("Что-то пошло не так! Попробуйте поменять введенные данные")
                            }

                        })

                }}
                validationSchema={infoSchema}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <div className={s.loginForm}>
                            <h1>Регистрация</h1>
                            <div className={s.loginInfo}>
                                <label>
                                    Логин пользователя
                                </label>
                                <Field type="username"
                                       name="username"/>
                                {errors.username && touched.username ? (
                                    <div className={s.wrong}>{errors.username}</div>
                                ) : null}
                                <label>
                                    Почта пользователя
                                </label>
                                <Field type="email"
                                       name="email"/>
                                {errors.email && touched.email ? (
                                    <div className={s.wrong}>{errors.email}</div>
                                ) : null}
                                <label>
                                    Пароль
                                </label>
                                <Field type="password"
                                       name="password"/>
                                {errors.password && touched.password ? (
                                    <div className={s.wrong}>{errors.password}</div>
                                ) : null}
                                <button type="submit">
                                    Отправить
                                </button>
                                {errorM !== '' ?
                                    <div style={{color: "red", textAlign: "center"}}>{errorM}</div> : <></>}
                                <div className={s.registration}>
                                    <p>
                                        Уже зарегистрированы?
                                    </p>
                                    <p>
                                        <a href="/login">Авторизация</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Hints/>
        </div>
    )
}
const Hints = () => {
    return (
        <div className={s.loginForm}>
            <h1 style={{fontSize: "24px"}}>Часто возникающие проблемы</h1>
            <div className={s.loginInfo}>
                <ul>
                    <li>
                        Логин - уникальный псевдоним пользователя. Если при регистрации приходит уведомление, что
                        пользователь с таким логином уже существует - немного отредактируйте существующий. Также в
                        качестве
                        псевдонима можно использовать свою почту
                    </li>
                    <li>
                        Использование пробелов в логине запрещено
                    </li>
                    <li>
                        Пароль должен имень от 8 символов, содержать хотя бы одну букву и одну цифру.
                    </li>
                    <li>
                        Простые пароли (например, qwerty1) система также может не принять
                    </li>
                    <li>
                        Если при отправке данных появилось сообщение "Что-то пошло не так" - попробуйте поменять данные или
                        напишите в тех. поддержку запрос с текущими
                    </li>
                </ul>
            </div>

        </div>
    )
}
export default AccRegistration

