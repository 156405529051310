import {authAPI} from "../api/api";
import {useNavigate} from "react-router-dom";
const SET_USER_DATA = 'SET_USER_DATA'

let initialState = {
    id: null,
    email: null,
    username: null,
    isAuth: false,
}


export const actions = {
    setAuthUserData:(id, email, username, isAuth) => ({
        type: 'SET_USER_DATA',
        payload:{id, email, username, isAuth}
    }),
}
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

//export const setAuthUserData = (id, email, username, isAuth) => ({type: SET_USER_DATA, payload:{id, email, username, isAuth}})

export const getUserAuthData = () => async (dispatch) => {
        await authAPI.me(localStorage.getItem("access"))
            .then(response => response.text())
            .then(result => {
                let userData = JSON.parse(result)
                let {email, id, username} = userData;
                const isAuth = true;
                dispatch(actions.setAuthUserData(id, email, username, isAuth))
            }).catch(err => {

            })
}
export const logout = () => (dispatch) => {
    authAPI.logout().then(response => {
            dispatch(actions.setAuthUserData(null , null, null, false ))
            localStorage.removeItem('access')

        });
}


export default authReducer
