import m from "../../CoursesExamples/CourseEx.module.css"
import {CourseDescription, CourseFilter, PageTitle, SubTitle} from "../../Modules/Modules";
import {TeacherBlock} from "../../CoursesExamples/CourseExample1";
import s from "../../Modules/Modules.module.css";
import {MC2Array} from "../../CoursesExamples/MediationCourse2";
import file1
    from "../../../assets/FilesForDeleteInFuture/PDP2/Учебно-тематический план.pdf"
import file2 from "../../../assets/FilesForDeleteInFuture/PDP2/Расписание занятий.pdf"
import {PDP2Array} from "../../CoursesExamples/PdpCourse2";

const PDP2Information = () => {
    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Профилактика девиантного поведения"/>
                    <PageTitle
                        title="Психологические проблемы детско-подросткового возраста: профилактика и коррекция в общеобразовательных организациях"/>
                    <TeacherBlock name="Артамонова Елена Геннадьевна"
                                  position='Заместитель директора по развитию системы профилактики девиантного поведения ФГБУ "Центр защиты прав и интересов детей", кандидат психологических наук'/>
                    <p style={{fontSize: "30px"}}></p>
                    <CourseDescription title="Информация для ознакомления">
                        <CourseDesc/>
                    </CourseDescription>
                    <p style={{fontSize: "30px"}}></p>
                    <CourseDescription title="Ссылки для подключения к занятиям">
                        <CourseDesc2/>
                    </CourseDescription>
                    {/*<p style={{fontSize: "30px"}}></p>*/}
                    {/*<CourseDescription title="Презентации занятий">*/}
                    {/*    <Presentations/>*/}
                    {/*</CourseDescription>*/}
                    {/*<p style={{fontSize: "30px"}}></p>*/}
                    {/*<CourseDescription title="Самостоятельная работа">*/}
                    {/*    <IndependentWork/>*/}
                    {/*</CourseDescription>*/}
                </div>
                <div className={m.courseRight}>
                    <CourseFilter filterArray={PDP2Array}/>
                </div>
            </div>

        </div>
    )
}
export default PDP2Information

const CourseDesc = () => {
    return <div className={s.courseDescription}>
        <div className={s.ref}>
            <a href={file1}>Учебно-тематический план</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href={file2}>Расписание занятий</a>
        </div>
        <p></p>
        <div className={s.ref}>
            <a href="https://t.me/+kFG3BxFNzeg4Mjky">Группа в телеграм</a>
        </div>
    </div>
}

const CourseDesc2 = () => {
    return <div className={s.courseDescription}>
        <div className={s.ref}>
            Дата: 20.05.24<br/>
            Время: 14:00 – 16:00<br/>
            <a href="https://my.mts-link.ru/j/19466565/1928864324">https://my.mts-link.ru/j/19466565/1928864324</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 22.05.24<br/>
            Время: 14:00 – 16:00<br/>
            <a href="https://my.mts-link.ru/j/19466565/1938566733">https://my.mts-link.ru/j/19466565/1938566733</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 24.05.24<br/>
            Время: 14:00 – 16:00<br/>
            <a href="https://my.mts-link.ru/j/19466565/890035242">https://my.mts-link.ru/j/19466565/890035242</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 27.05.24<br/>
            Время: 14:00 – 16:00<br/>
            <a href="https://my.mts-link.ru/j/19466565/409439456">https://my.mts-link.ru/j/19466565/409439456</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 29.05.24<br/>
            Время: 14:00 – 16:00<br/>
            <a href="https://my.mts-link.ru/j/19466565/715076307">https://my.mts-link.ru/j/19466565/715076307</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 31.05.24<br/>
            Время: 14:00 – 16:00<br/>
            <a href="https://my.mts-link.ru/j/19466565/925592792">https://my.mts-link.ru/j/19466565/925592792</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 03.06.24<br/>
            Время: 14:00 – 16:00<br/>
            <a href="https://my.mts-link.ru/j/19466565/987898029">https://my.mts-link.ru/j/19466565/987898029</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 05.06.24<br/>
            Время: 14:00 – 16:00<br/>
            <a href="https://my.mts-link.ru/j/19466565/1061645962">https://my.mts-link.ru/j/19466565/1061645962</a>
        </div>
        <p></p>
        <div className={s.ref}>
            Дата: 07.06.24<br/>
            Время: 14:00 – 17:00<br/>
            <a href="https://my.mts-link.ru/j/19466565/1989644753">https://my.mts-link.ru/j/19466565/1989644753</a>
        </div>
        <p></p>
    </div>
}
//
// const IndependentWork = () => {
//     return <div className={s.courseDescription}>
//         <div className={s.ref}>
//             <a href={iw1}>Тема 1.1 Задание для самостоятельной работы</a>
//         </div>
//         <p></p>
//         <div className={s.ref}>
//             <a href={iw2}>Тема 2.3 Задание для самостоятельной работы</a>
//         </div>
//         <p></p>
//     </div>
// }
//
// const Presentations = () => {
//     return <div className={s.courseDescription}>
//         <div className={s.ref}>
//             <a href={pres1}>Презентация Тема 1.1. 15.04.2024</a>
//         </div>
//         <p></p>
//         <div className={s.ref}>
//             <a href={file4}>Презентация Лашкул М.В.</a>
//         </div>
//         <p></p>
//         <div className={s.ref}>
//             <a href={pres2}>Презентация Г.В. Семья</a>
//         </div>
//         <p></p>
//         <div className={s.ref}>
//             <a href={pres3}>Засемкова О.Ф. - Презентация ДПО</a>
//         </div>
//         <p></p>
//         <div className={s.ref}>
//             <a href={pres4}>Савенко О.Е. - Презентация ДПО</a>
//         </div>
//         <p></p>
//         <div className={s.ref}>
//             <a href={pres5}>Статистика</a>
//         </div>
//         <p></p>
//         <div className={s.ref}>
//             <a href={pres6}>Презентация Тюльканов - ДПО</a>
//         </div>
//         <p></p>
//     </div>
// }

