import s from "./Main.module.css"
import {Field, Form, Formik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import {useState} from "react";


const NewPassword = () => {

    const [errorM, setErrorM] = useState('')
    const {uid, token} = useParams();

    let navigate = useNavigate()


    return (
        <div className={s.main}>
            <Formik
                initialValues={{new_password: "", re_new_password: ""}}
                onSubmit={async (values) => {
                    let formData = new FormData();
                    formData.append("uid", uid);
                    formData.append("token", token);
                    let myHeaders = new Headers();
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formData,
                        redirect: 'follow',
                        withCredentials: true
                    }
                    const baseURL = 'https://dpo.fcprc.ru/api/'
                    //login(requestOptions)
                    fetch(baseURL + "auth/users/activation/", requestOptions)
                        .then(response =>
                            response.text()
                        )
                        .then((result) => {
                                let resp = result ? JSON.parse(result) : ''
                                if (resp && resp !=='') {
                                    setErrorM("Что-то пошло не так! Обратитесь в техподдержку или повторите попытку позже.")
                                } else {
                                    navigate("/")
                                }
                            }
                        )
                }}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <div className={s.loginForm}>
                            <h1>Активация аккаунта</h1>
                            <div className={s.loginInfo}>
                                <button type="submit">
                                    Подтвердить!
                                </button>
                                {errorM !== '' ?
                                    <div style={{color: "red", textAlign: "center"}}>{errorM}</div> : <></>}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
export default NewPassword

