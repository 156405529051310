import s from "./Main.module.css";
import {PageTitle} from "../Modules/Modules";
import {useEffect, useState} from "react";
import axios from "axios";
import {DocRef} from "../Modules/Buttons";
import doc from "../../assets/FilesForDeleteInFuture/Приказ № 59 от 11.09.2023 Правила приема, зачисления, перевода, отчисления и восстановления.pdf"
import doc2 from "../../assets/FilesForDeleteInFuture/ОП 36 ч. Рассмотрение споров родителей, связанных с воспитанием детей.pdf"
import phys from "../../assets/Files/Физ.лицо Договор об оказании платных образовательных услуг.doc"
import ur from "../../assets/Files/Юр.лицо Договор об оказании платных образовательных услуг.doc"

const OrganizationInfo = () => {

    const [respData, setData] = useState("")


    useEffect(() => {
        axios.get("https://dpo.fcprc.ru/api/NDFZ").then(
            res => {
                setData(res)
            })
    }, [])

    return (
        <div className={s.main}>
            <PageTitle title="Сведения об образовательной деятельности"/>
            <DocTitle title="Документы"/>
            <DocRef title="Правила приема, зачисления, перевода, отчисления и восстановления"
                    url={doc}/>
            <DocRef title={respData ? respData.data[1].description : ""}
                    url={respData ? respData.data[1].file : ""}/>
            <DocRef title={respData ? respData.data[2].description : ""}
                    url={respData ? respData.data[2].file : ""}/>
            <DocRef title={respData ? respData.data[3].description : ""}
                    url={respData ? respData.data[3].file : ""}/>
            <DocTitle title="Платные образовательные услуги"/>
            <DocRef title={respData ? respData.data[4].description : ""}
                    url={respData ? respData.data[4].file : ""}/>
            <DocRef title="Физ.лицо: Договор об оказании платных образовательных услуг"
                    url={phys}/>
            <DocRef title="Юр.лицо: Договор об оказании платных образовательных услуг"
                    url={ur}/>
            <DocTitle title="Техническое обеспечение и оснащенность"/>
            <DocRef title={respData ? respData.data[7].description : ""}
                    url={respData ? respData.data[7].file : ""}/>
            <DocTitle title="Утвержденные дополнительные профессиональные программы"/>
            <DocRef title="ОП Рассмотрение споров родителей, связанных с воспитанием детей"
                    url={doc2}/>
            <DocRef title={respData ? respData.data[8].description : ""}
                    url={respData ? respData.data[8].file : ""}/>
            <DocRef title={respData ? respData.data[9].description : ""}
                    url={respData ? respData.data[9].file : ""}/>
            <DocRef title={respData ? respData.data[10].description : ""}
                    url={respData ? respData.data[10].file : ""}/>
            <DocRef title={respData ? respData.data[11].description : ""}
                    url={respData ? respData.data[11].file : ""}/>
            <DocRef title={respData ? respData.data[12].description : ""}
                    url={respData ? respData.data[12].file : ""}/>
            <DocTitle title="Доступная среда"/>
            <DocRef title={respData ? respData.data[13].description : ""}
                    url={respData ? respData.data[13].file : ""}/>

        </div>
    )
}

export default OrganizationInfo

export const DocTitle = ({title}) => {
    return (
        <div className={s.bigTitle}>
            {title}
        </div>
    )
}
